import React, { useState } from "react";

interface IProps {
  name: string;
  defaultValue?: string | null;
  onChange: (value: string) => void;
}

interface IRadioContext {
  active: string | null;
  name: string;
  onActive: (active: string) => void;
}

export const RadioContext = React.createContext<IRadioContext>(null!);

export default function RadioGroup({
  children,
  name,
  defaultValue = null,
  onChange,
}: React.PropsWithChildren<IProps>) {
  const [active, setActive] = useState<string | null>(defaultValue);

  const value = {
    active,
    name,
    onActive: (active: string) => {
      setActive(active);
      onChange(active);
    },
  };

  return (
    <RadioContext.Provider value={value}>
      <div className="flex">{children}</div>
    </RadioContext.Provider>
  );
}
