import { IModalProps } from "interfaces/common.interface";
import React, { useCallback, useState } from "react";

interface Props {
  components: {
    [key in string]: React.LazyExoticComponent<
      React.FunctionComponent<IModalProps>
    >;
  };
}

export interface TParameter {
  key: string;
  value?: boolean;
  parameter?: any;
}

export interface IModalContext {
  toggle: (params: TParameter) => void;
  bus: { [key in string]: any };
}

export const ModalContext = React.createContext<IModalContext>(null!);

const ModalProvider = ({
  children,
  components,
}: React.PropsWithChildren<Props>) => {
  const [modals, setModals] = useState<{ [key in string]: boolean }>({});
  const [bus, setBus] = useState<{ [key in string]: any }>({});

  const toggle = useCallback(({ key, value, parameter }: TParameter) => {
    setBus((prevState) => {
      prevState[key] = parameter;
      return { ...prevState };
    });

    setModals((prevState) => {
      prevState[key] = typeof value === "boolean" ? value : !prevState[key];
      return { ...prevState };
    });
  }, []);

  const closeHandler = (key: string, beforeClose?: () => void) => {
    beforeClose && beforeClose();

    setBus((prevState) => {
      delete prevState[key];
      return { ...prevState };
    });

    setModals((prevState) => {
      prevState[key] = false;
      return { ...prevState };
    });
  };

  const value = {
    toggle,
    bus,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
      {Object.keys(components).map((key) => {
        const Component = components[key];
        if (modals)
          return (
            <Component
              key={key}
              bus={bus[key]}
              show={modals[key]}
              onClose={closeHandler}
            />
          );
        return null;
      })}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
