import { classNameJoin } from "utils/ui.lib";
import Progress from "./Progress";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

const Button = ({
  children,
  className,
  disabled,
  loading,
  ...props
}: React.PropsWithChildren<Props>) => {
  return (
    <button
      className={classNameJoin([
        "whitespace-nowrap h-9 px-4 space-x-2 items-center bg-neutral-700 text-white text-sm rounded-sm select-none transition duration-300 disabled:bg-slate-200 disabled:text-slate-400",
        className,
      ])}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <Progress size={20} /> : children}
    </button>
  );
};

export default Button;
