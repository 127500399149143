import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { comma } from "utils/lib";
import { useAlert } from "utils/helper";
import {
  BarItem,
  DataItem,
  Item,
  Line,
  MA,
  MACD,
  MovingAverageMethodType,
  PointItem,
} from "interfaces/backtest.interface";
import { IMaterialsPack } from "interfaces/material.interface";

import BackTestGraph from "components/graph/BackTestGraph";
import Material from "components/shared/Materials";
import MovingAverageMethod from "./MovingAverageMethod";
import MovingAverageDays from "./MovingAverageDays";
import { calcSignal, calcSignalForMACD, clearMemory, toFixedFloat } from "./calc";
import calc from "./calc";

interface Props {
  data: DataItem[];
  isUpdate: boolean;
  setIsUpdate: () => void;
}

export default function Middleware({ data, isUpdate, setIsUpdate }: Props) {
  const alert = useAlert();

  const [strengthLength, setStrengthLength] = useState(14);

  const [movingAverage, setMovingAverage] = useState<number[]>([]);
  const [movingAverageColor, setMovingAverageColor] = useState<string[]>([]);
  const [movingAverageMethod, setMovingAverageMethod] = useState<MovingAverageMethodType>("SMA");
  const [strengthType, setStrengthType] = useState("RSI");
  const [lim, setLim] = useState({ min: 0, max: 0 });
  const [range, setRange] = useState({ start: "", end: "" });
  const [maSettings, setMaSettings] = useState<MA[]>([
    { color: "#ff8480", length: "10", visible: true },
    { length: "20", color: "#ff3333", visible: true },
    { color: "#8fb4ff", length: "50", visible: true },
    { length: "60", color: "#38ff59", visible: true },
    { color: "#00c721", length: "112", visible: true },
    { length: "150", color: "#2970ff", visible: true },
    { color: "#003aad", length: "200", visible: true },
    { length: "224", color: "#ff2eee", visible: true },
    { color: "#ab0fff", length: "300", visible: true },
  ]);

  // Stock
  const [items, setItems] = useState<BarItem[]>([]);
  // RSI or MFI
  const [strength, setStrength] = useState<Item[]>([]);
  // Moving Average
  const [ma, setMa] = useState<{ [key: string]: Item[] }>({});
  // Event Point
  const [point, setPoint] = useState<PointItem[]>([]);
  // Custom Line
  const [dynamicLines, setDynamicLines] = useState<Line[]>([]);
  // Moving Average Convergence Divergence
  const [macd, setMACD] = useState<MACD>({
    histogram: [],
    signal: [],
    value: [],
  });

  // Material
  const [materials, setMaterials] = useState<IMaterialsPack>({});

  /**
   *
   */
  const settingMa = useCallback(() => {
    let storedMa = localStorage.getItem("MA_SET");

    const settings = !storedMa ? maSettings : (JSON.parse(storedMa) as MA[]);
    setMaSettings(settings);

    const visible = settings.filter((d) => d.visible === true);

    const lengths = visible.map(({ length }) => parseInt(length.toString()));
    setMovingAverage(lengths);

    const colors = visible.map(({ color }) => color);
    setMovingAverageColor(colors);
  }, []);

  /**
   *
   */
  const saveMaSetting = () => {
    const checkedEmpty = maSettings.filter(({ length }) => length === "" || !length);

    if (checkedEmpty.length > 0) {
      alert.error("빈 값을 채워주세요.");
      return;
    }

    const sortedSetting = maSettings.sort((a, b) => parseInt(a.length) - parseInt(b.length));

    setMaSettings(sortedSetting);

    const visible = sortedSetting.filter((d) => d.visible === true);

    const lengths = visible.map((d) => parseInt(d.length.toString()));
    setMovingAverage(lengths);

    const colors = visible.map((d) => d.color);
    setMovingAverageColor(colors);

    localStorage.setItem("MA_SET", JSON.stringify(sortedSetting));
    alert.success("설정이 저장되었습니다.");
  };

  /**
   *
   */
  useEffect(() => {
    const items: BarItem[] = [];
    const strength: Item[] = [];
    const point: PointItem[] = [];
    const ma: { [key: string]: Item[] } = {};
    const macd: MACD = {
      value: [],
      signal: [],
      histogram: [],
    };

    let min = 0,
      max = 0,
      before = -1,
      macdLow = 12,
      macdHigh = 26,
      signalLength = 9;

    if (data.length === 0) return;

    const last = data[data.length - 1];
    const beforeOneYear = dayjs(last.date).subtract(1, "year");

    let new52WeekHigh: Item = {
      x: new Date(last.date),
      y: last.close,
    };

    for (let i = 0; i < data.length; i++) {
      const { date, low, high, close, open } = data[i];

      if (beforeOneYear.isBefore(dayjs(date))) {
        if (new52WeekHigh.y < close) new52WeekHigh = { x: new Date(date), y: close };
      }

      // 최초 min, max 설정
      if (i === 0) {
        min = low;
        max = high;
      }

      // 기본 데이터
      items.push({
        x: new Date(date),
        y: close,
        h: high,
        l: low,
        o: open,
      });

      // 이평선 계산
      for (let j = 0; j < movingAverage.length; j++) {
        const length = movingAverage[j];
        const key = `MA_${length}`;

        if (!ma[key]) ma[key] = [];

        if (i >= length) {
          // moving average value
          const mav = calc[movingAverageMethod](data, i, length - 1);
          ma[key].push(mav);
        }
      }

      // MACD
      // MACD = Short SMA Index (12) - Long SMA Index (26)
      // Signal = MACD / N (period)
      // Histogram = MACD - Signal
      if (i >= macdHigh) {
        const sv = calc.SMA(data, i, macdLow - 1); // short value
        const lv = calc.SMA(data, i, macdHigh - 1); // long value
        const date = new Date(data[i].date);
        const value = toFixedFloat(sv.y - lv.y); // macd histogram

        macd.value.push({ x: date, y: value });

        // signal
        if (i >= macdHigh + signalLength) {
          const signal = calcSignalForMACD(macd.value, i - macdHigh, signalLength - 1);
          macd.signal.push(signal);
          macd.histogram.push({ x: date, y: value - signal.y });
        }
      }

      // RSI 계산
      const strengthDay = (strengthType === "RSI" ? strengthLength : strengthLength + 1) - 1;
      const signalDay = 9;

      if (i >= strengthDay) {
        const d = strengthType === "RSI" ? calc.RSI(data, i, strengthDay) : calc.MFI(data, i, strengthDay);

        strength.push(d);

        if (i >= strengthDay + signalDay) {
          const signal = calcSignal(strength, i - strengthDay, signalDay - 1);
          strength[strength.length - 1].signal = signal;
        }

        // RSI/MFI 이벤트 추가
        if (strengthType === "RSI") {
          if (before !== -1 && before < 30 && d.y > 30) {
            point.push({ x: d.x, y: data[i].close, event: "STR_UP" }); // 30 에서 상승
          } else if (before !== -1 && before > 70 && d.y < 70) {
            point.push({ x: d.x, y: data[i].close, event: "STR_DOWN" }); // 70 에서 하락
          }
        } else if (strengthType === "MFI") {
          if (before !== -1 && before < 20 && d.y > 20) {
            point.push({ x: d.x, y: data[i].close, event: "STR_UP" }); // 20 에서 상승
          } else if (before !== -1 && before > 80 && d.y < 80) {
            point.push({ x: d.x, y: data[i].close, event: "STR_DOWN" }); // 80 에서 하락
          }
        }

        before = d.y;
      }

      if (min > low) min = low;
      if (max < high) max = high;
    }

    point.push({ ...new52WeekHigh, event: "NEW_52_WEEK_HIGH" });

    setItems(items);
    setMa(ma);
    setStrength(strength);
    setPoint(point);
    setMACD(macd);
    setLim({ min, max });
    setRange({
      start: dayjs(data[0].date).format("YY/MM/DD"),
      end: dayjs(data[data.length - 1].date).format("YY/MM/DD"),
    });

    setIsUpdate();
    clearMemory();
  }, [data, movingAverage, movingAverageMethod, strengthLength, strengthType, setIsUpdate]);

  /**
   * 이평선 기본 세팅
   */
  useEffect(() => {
    settingMa();
  }, [settingMa]);

  /**
   * ticker 업데이트
   */
  useEffect(() => {
    if (isUpdate) {
      setDynamicLines([]);
    }
  }, [isUpdate]);

  return (
    <div className="flex h-full">
      <div className="w-[180px] ">
        <ul className="text-[12px] space-y-2">
          <li>
            <div>
              <strong>Index</strong>
            </div>
            <div>
              <Material onChange={(data) => setMaterials(data)} />
            </div>
          </li>

          <li>
            <div>
              <strong>Strength</strong>
            </div>
            <div className="flex space-x-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="psychological"
                  checked={strengthType === "RSI"}
                  onChange={() => setStrengthType("RSI")}
                />
                <span className="ml-2">RSI</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="psychological"
                  checked={strengthType === "MFI"}
                  onChange={() => setStrengthType("MFI")}
                />
                <span className="ml-2">MFI</span>
              </label>
            </div>
          </li>

          <li>
            <strong>Moving Average Method</strong>
            <div>
              <MovingAverageMethod checked={movingAverageMethod} onChange={setMovingAverageMethod} />
            </div>
          </li>

          <li>
            <strong>Moving Average Days</strong>
            <div>
              <MovingAverageDays settings={maSettings} setSettings={setMaSettings} onSave={saveMaSetting} />
            </div>
          </li>
        </ul>
      </div>

      <div className="w-[calc(100%-180px)] h-full">
        <ul className="flex text-[12px] space-x-4">
          <li className="space-x-2">
            <strong>Price</strong>
            <span>{comma(lim.min)}</span>
            <span>~</span>
            <span>{comma(lim.max)}</span>
          </li>

          <li className="space-x-2">
            <strong>Date</strong>
            <span>{range.start}</span>
            <span>~</span>
            <span>{range.end}</span>
          </li>
        </ul>

        {/* <BackTestGraphV3 data={data} movingAverageColor={movingAverageColor} movingAverageIndex={movingAverage} /> */}

        {/* <BackTestGraphV2
          items={items}
          strength={strength}
          point={point}
          ma={ma}
          ylim={lim}
          strengthType={strengthType}
          movingAverageIndex={movingAverage}
          movingAverageColor={movingAverageColor}
        /> */}

        <BackTestGraph
          items={items}
          lim={lim}
          ma={ma}
          macd={macd}
          dynamicLines={dynamicLines}
          materials={materials}
          strengthType={strengthType}
          strength={strength}
          point={point}
          movingAverageIndex={movingAverage}
          movingAverageColor={movingAverageColor}
          setDynamicLines={setDynamicLines}
        />
      </div>
    </div>
  );
}
