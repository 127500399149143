import { classNameJoin } from "utils/ui.lib";
import "styles/modal.css";

interface ILayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  className?: string;
  show: boolean;
  onClose?: () => void;
}

const Layout = ({
  id,
  className,
  children,
  show,
  onClose,
  ...props
}: React.PropsWithChildren<ILayoutProps>) => {
  return (
    <div
      id={id}
      className={classNameJoin([
        className,
        "modal fixed top-0 bottom-0 left-0 right-0 z-20 bg-transparent",
        show ? "show" : null,
      ])}
      {...props}
    >
      <div
        className="absolute z-30 w-full h-full bg-black bg-opacity-60"
        onClick={onClose}
      />
      {children}
    </div>
  );
};

interface IContentProps {
  title?: string;
  className?: string;
}

const Content = ({
  children,
  className,
}: React.PropsWithChildren<IContentProps>) => {
  return (
    <div
      className={classNameJoin([
        "absolute flex left-0 right-0 bottom-4 z-40 m-4 md:left-[50%] md:translate-x-[-50%]",
        className,
      ])}
    >
      <div className="bg-white p-2 rounded-md w-full">{children}</div>
    </div>
  );
};

interface IHeaderProps {
  close?: boolean;
  onClose?: () => void;
}

const Header = ({
  children,
  close = true,
  onClose,
}: React.PropsWithChildren<IHeaderProps>) => {
  return (
    <div className="flex items-center justify-between mb-3">
      <h3 className="font-bold">{children}</h3>
      {close && (
        <button className="ml-4 text-3xl" onClick={onClose}>
          &#215;
        </button>
      )}
    </div>
  );
};

export default {
  Layout,
  Content,
  Header,
};
