import { DataItem, Item, Memory, MovingAverageMethodType } from "interfaces/backtest.interface";

/**
 * 데이터 보관소
 */
export const memory: Memory = {
  before: {},
};

/**
 * 보관소 초기화
 */
export function clearMemory() {
  memory.before = {};
}

/**
 *
 * @param num
 * @returns
 */
export const toFixedFloat = (num: number | string) => {
  return parseFloat(parseFloat(num.toString()).toFixed(2));
};

/**
 *
 * @param items
 * @param index
 * @param length
 * @returns
 */
export const calcSignalForMACD = (items: Item[], index: number, length: number) => {
  const cut = items.slice(index - length, index + 1);
  const length1 = length + 1;

  const price = cut[length].y;
  const date = items[index].x;

  const lengthOfBeforEMA = memory.before["MACD_SIGNAL"];

  // 이전 EMA 가 없으면 SMA 값으로 대신한다. (첫번째만 그럴 예정)
  const beforEMA = lengthOfBeforEMA ? lengthOfBeforEMA : cut.reduce((p, { y }) => p + y, 0) / length1;

  const k = 2 / (1 + length1);

  const ema = price * k + beforEMA * (1 - k);

  // 이전 EMA 저장
  memory.before["MACD_SIGNAL"] = ema;

  return {
    x: date,
    y: toFixedFloat(ema),
  };
};

/**
 *
 * @param items
 * @param index
 * @param length
 * @returns
 */
export const calcSignal = (items: Item[], index: number, length: number) => {
  const cut = items.slice(index - length, index + 1);
  const average = cut.reduce((p, { y }) => p + y, 0) / (length + 1);
  return toFixedFloat(average);
};

// ----------------------------

/**
 * Simple Moving Average 계산기
 * @param items
 * @param index
 * @param length
 * @returns
 */
const calcSMA = (items: DataItem[], index: number, length: number): Item => {
  const cut = items.slice(index - length, index + 1);
  const date = items[index].date;
  const average = cut.reduce((p, { close }) => p + close, 0) / (length + 1);

  return {
    x: new Date(date),
    y: toFixedFloat(average),
  };
};

/**
 * Weight Moving Average 계산기
 * (n) * P0 + (n - 1) * P-1 + ... + (1) * P -(n-1) / n + (n+1) + ... +1
 * @param items
 * @param index
 * @param length
 * @returns
 */
const calcWMA = (items: DataItem[], index: number, length: number): Item => {
  const cut = items.slice(index - length, index + 1);
  const date = items[index].date;

  let p = 0;
  let share = 0;

  for (let i = 0; i < cut.length; i++) {
    p += (i + 1) * cut[i].close;
    share += i + 1;
  }

  const average = p / share;

  return {
    x: new Date(date),
    y: toFixedFloat(average),
  };
};

/**
 * period = 영업일수
 * C = 현재가격
 * K(승수) = 2 / (1 + period)
 * EMA(i) = K * C + (EMA(i-1) * (1-K))
 * @param items
 * @param index
 * @param length
 * @returns
 */

const calcEMA = (items: DataItem[], index: number, length: number): Item => {
  const cut = items.slice(index - length, index + 1);
  const length1 = length + 1;
  const price = cut[length].close;
  const date = items[index].date;

  const lengthOfBeforEMA = memory.before[`MA_${length1}`];

  // 이전 EMA 가 없으면 SMA 값으로 대신한다. (첫번째만 그럴 예정)
  const beforeEMA = lengthOfBeforEMA ? lengthOfBeforEMA : cut.reduce((p, { close }) => p + close, 0) / length1;

  const k = 2 / (1 + length1);

  const ema = price * k + beforeEMA * (1 - k);

  // 이전 EMA 저장
  memory.before[`MA_${length1}`] = ema;

  return {
    x: new Date(date),
    y: toFixedFloat(ema),
  };
};

/**
 *
 * @param items
 * @param index
 * @param length
 * @returns
 */
const calcRSI = (items: DataItem[], index: number, length: number): Item => {
  const cut = items.slice(index - length, index + 1);
  const date = items[index].date;

  let au = 0,
    ad = 0;

  // 최초 로드
  if (index === length) {
    for (let i = 1; i < cut.length; i++) {
      au += Math.max(0, cut[i].close - cut[i - 1].close);
      ad += Math.max(0, cut[i - 1].close - cut[i].close);
    }
  } else {
    const bau = memory.before["AU"] || 0;
    const bad = memory.before["AD"] || 0;
    const cau = Math.max(0, cut[length].close - cut[length - 1].close);
    const cad = Math.max(0, cut[length - 1].close - cut[length].close);

    au = bau * length + cau;
    ad = bad * length + cad;
  }

  au /= length + 1;
  ad /= length + 1;

  memory.before["AU"] = au;
  memory.before["AD"] = ad;

  return {
    x: new Date(date),
    y: toFixedFloat((au / (au + ad)) * 100),
  };
};

/**
 *
 * TP (Typical Price) = 고가 + 저가 + 종가 / 3
 * MF (Money Flow) = TP * 거래량
 * SUM(MF+) = N 일간 TP 증가일의 MF 합산
 * SUM(MF-) = N 일간 TP 하락일의 MF 합산
 *
 * Money Flow Ratio = SUM(MF+) / SUM(MF-)
 * MFI = 100 - 100 / (1- Money Flow Ratio)
 * @param items
 * @param index
 * @param length
 * @returns
 */
const calcMFI = (items: DataItem[], index: number, length: number): Item => {
  const cut = items.slice(index - length, index + 1);
  const date = items[index].date;

  /** TP 계산 */
  function typicalPrice(data: DataItem) {
    const { close, high, low } = data;
    return (high + low + close) / 3;
  }

  let positive = 0,
    nagative = 0;

  const data = cut.map((data) => {
    const tp = toFixedFloat(typicalPrice(data));
    return { tp, rawMF: tp * data.volume };
  });

  for (let i = 1; i < data.length; i++) {
    const tp0 = data[i - 1].tp; // 전일
    const tp1 = data[i].tp; // 현재

    positive += tp1 > tp0 ? data[i].rawMF : 0;
    nagative += tp1 < tp0 ? data[i].rawMF : 0;
  }

  return {
    x: new Date(date),
    y: toFixedFloat(100 - 100 / (1 + positive / nagative)),
  };
};

export default {
  SMA: calcSMA,
  WMA: calcWMA,
  EMA: calcEMA,
  RSI: calcRSI,
  MFI: calcMFI,
} as {
  [key in MovingAverageMethodType]: (items: DataItem[], index: number, length: number) => Item;
};
