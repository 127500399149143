import pako from "pako";
import { Buffer } from "buffer";
import html2canvas, { Options } from "html2canvas";

/**
 *
 * @param obj
 * @param key
 * @returns
 */
export const hasOwnProperty = (obj: object, key: string) =>
  Object.prototype.hasOwnProperty.call(obj, key);

/**
 *
 * @returns
 */
export const suid = () => (Math.random() * 10000).toFixed(4);

/**
 *
 * @param val
 * @param type
 * @returns
 */
export const toNumber = (
  val: string | number,
  type: "float" | "int" = "int"
) => {
  const rmComma = val.toString().replace(/,/g, "");
  return type === "int" ? parseInt(rmComma) : parseFloat(rmComma);
};

/**
 *
 * @param cap
 * @returns
 */
export const priceToText = (price?: number | string) => {
  if (!price) return "0원";
  const thousand = parseInt(price.toString().replace(/,/g, "")) / 10000;

  if (thousand > 10000 || thousand < -10000) {
    const [int] = (thousand / 10000).toString().split(".");
    const million = parseInt(int);

    if (million > 10000 || million < -10000) {
      const [int, float] = (million / 10000).toString().split(".");
      const billion = parseInt(int);
      return `${comma(billion)}조 ${comma(parseInt(float))}억`;
    }

    return comma(million) + "억";
  }

  return comma(thousand) + "천만";
};

/**
 *
 * @param num
 * @returns
 */
export const comma = (num?: number | string | null) =>
  num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;

/**
 *
 * @param str
 * @returns
 */
export const rmComma = (str: string) => str.replace(/,/g, "");

/**
 *
 * @param str
 * @returns
 */
export const reComma = (str: string) => comma(rmComma(str));

/**
 *
 * @param html
 * @returns
 */
export const escape = (html: string) =>
  html
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/'/g, "&#x27;")
    .replace(/`/g, "&#x60;")
    .replace(/"/g, "&#x22;");

/**
 *
 * @param content
 * @returns
 */
export const html = (content?: string) =>
  !content
    ? ""
    : content
        .replace(/(&lt;)/g, "<")
        .replace(/(&gt;)/g, ">")
        .replace(/(&#x27;)/g, "'")
        .replace(/(&#x60;)/g, "`")
        .replace(/(&#x22;)/g, '"');

/**
 *
 * @param v
 * @returns
 */
function zero(v: number | string) {
  if (typeof v === "number") return v < 10 ? "0" + v : v;
  if (typeof v === "string") return v.length === 1 ? "0" + v : v;
  return v;
}

/**
 *
 * @param value
 * @returns
 */
function toHex(value?: number) {
  let _value = value === undefined ? Math.floor(Math.random() * 256) : value;
  _value = _value > 255 ? 0 : _value;
  let hex = _value.toString(16);
  return zero(hex);
}

/**
 *
 * @param rgb
 * @returns
 */
export const getRandomColor = (rgb?: {
  r?: number;
  g?: number;
  b?: number;
}) => {
  if (!rgb) return [toHex(), toHex(), toHex()].join("");
  return [toHex(rgb.r), toHex(rgb.g), toHex(rgb.b)].join("");
};

/**
 *
 * @returns
 */
export const getRandomHex = (defaultHex?: string[]) => {
  let _defaultHex = defaultHex || [];
  let result = [..._defaultHex];
  let hexRef = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
  ];

  for (let n = 0; n < 6 - _defaultHex.length; n++) {
    result.push(hexRef[Math.floor(Math.random() * 16)]);
  }
  return result.join("");
};

/**
 *
 * @param nums
 * @param returnType
 * @returns
 */
export const sum = (
  nums: (string | number)[],
  returnType: "string" | "number" = "string"
) => {
  const result = nums.reduce((p, c) => {
    if (!c) return p;
    return parseInt(p.toString()) + parseInt(c.toString());
  }, 0);

  if (returnType === "number") {
    return parseInt(result.toString());
  }

  return result.toString();
};

/**
 *
 * @param nums
 * @param returnType
 * @returns
 */
export const multiply = (
  nums: (string | number)[],
  returnType: "string" | "number" = "string"
) => {
  const result = nums.reduce((p, c) => {
    if (!c) return p;

    return parseInt(p.toString()) * parseInt(c.toString());
  }, 1);

  if (returnType === "string") {
    return result.toString();
  }

  if (returnType === "number") {
    return parseInt(result.toString());
  }

  return result;
};

/**
 *
 * @param start
 * @param end
 * @returns
 */
export const rate = (start: number | string, end: number | string) => {
  const share = parseFloat(start.toString() || "0") / 100; // share
  const substact =
    parseFloat(end.toString() || "0") - parseFloat(start.toString() || "0");
  const result = parseFloat((substact / share).toFixed(2));

  return isNaN(result) || result === Infinity || result === -Infinity
    ? 0
    : result;
};

/**
 *
 * @param price
 * @param volume
 * @param rate
 * @returns
 */
export const profit = (price: number, volume: number, rate: number) => {
  const total = parseInt(
    Math.floor(multiply([price, volume], "number") as number).toString()
  );
  return Math.floor(total * (rate / 100));
};

export const base64_to_json = <T = undefined>(compressed: string) => {
  try {
    const view = Buffer.from(compressed, "base64").toString("utf-8");
    const uint = new Uint8Array(JSON.parse(view));
    const result = pako.inflate(uint, { to: "string" });
    const transferQuote = result.replace(/'/g, '"');
    return JSON.parse(transferQuote) as T;
  } catch (err) {
    console.log(err);
  }
};

/**
 * HTML 요소를 이미지로 만들어서 파일로 돌려줌
 * @param element
 * @param filename
 * @param type
 * @param options
 * @returns
 */
export const elementToImage = (
  element: HTMLElement,
  filename: string,
  type = "image/png",
  options?: Partial<Options>
): Promise<File> => {
  return new Promise(async (resolve, reject) => {
    const canvas = await html2canvas(element, {
      logging: true,
      allowTaint: false,
      useCORS: true,
      ...options,
    });

    canvas.toBlob((blob) => {
      if (!blob) {
        reject("FAIL");
        return;
      }
      const file = new File([blob], filename, { type });
      resolve(file);
    });
  });
};

/**
 * 파일 다운로드
 */
export const fileDownload = (file: File, filename: string) => {
  const url = URL.createObjectURL(file);
  const a = document.createElement("a");

  a.href = url;
  a.download = filename;
  a.click();
  a.remove();

  URL.revokeObjectURL(url);
};

/**
 * readEntries
 * @param reader
 * @returns
 */
const readEntries = async (reader: FileSystemDirectoryReader) => {
  return new Promise<File[]>((resolve, reject) => {
    reader.readEntries(async function (entries) {
      const files: File[] = [];

      if (entries.length === 0) {
        resolve(files);
        return;
      }

      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];
        if (entry.isFile) {
          const file = await getEntryFile(entry as FileSystemFileEntry);
          files.push(file);

          if (i === entries.length - 1) {
            resolve(files);
          }
        }
      }
    });
  });
};

/**
 * readFileInDirectory
 */
export const readFileInDirectory = (
  entry: FileSystemEntry,
  extension?: string
) => {
  const dict = entry as FileSystemDirectoryEntry;

  return new Promise<File[]>(async (resolve, reject) => {
    const files: File[] = [];
    const reader = dict.createReader();

    let isContinue = true;

    while (isContinue) {
      const entries = await readEntries(reader);
      files.push(...entries);

      if (entries.length === 0) isContinue = false;
    }

    resolve(files);
  });
};

/**
 *
 * @param entry
 * @returns
 */
export const getEntryFile = (entry: FileSystemFileEntry) => {
  return new Promise<File>((resolve, reject) => {
    entry.file(
      (file) => resolve(file),
      (err) => reject(err)
    );
  });
};

/**
 *
 */
export const debounce = (callback: (args?: any) => void, duration = 1000) => {
  let timer: NodeJS.Timeout | null = null;

  return function (args?: any) {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      callback(args);
    }, duration);
  };
};
