import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import CalculatorPage from "./calculator/CalculatorPage";
import BackTestPage from "./backtest/BackTestPage";

// Home
export const Home = React.lazy(() => import("./Home"));

// User
export const MyPage = React.lazy(() => import("./user/MyPage"));

// S-RIM
export const SRimPage = React.lazy(() => import("./quant/SRimPage"));

// Company
export const CompanySearchPage = React.lazy(() => import("./company/CompanySearchPage"));
export const CompanyViewPage = React.lazy(() => import("./company/CompanyViewPage"));
export const CompanyEditPage = React.lazy(() => import("./company/CompanyEditPage"));

// Theme
export const ThemeListPage = React.lazy(() => import("./theme/ThemeListPage"));
export const ThemeEditPage = React.lazy(() => import("./theme/ThemeEditPage"));

// ShareHolder
export const ShareHolderListPage = React.lazy(() => import("./shareholder/ShareHolderListPage"));

// Slider
export const SliderPage = React.lazy(() => import("./slider/SliderPage"));

// Monthly Chart
export const MonthlyPage = React.lazy(() => import("./indicator/MonthlyPage"));

export const OilStockPage = React.lazy(() => import("./indicator/OilStockPage"));

// Calculator
export const CalculatorViewPage = React.lazy(() => import("./calculator/CalculatorViewPage"));
export const CalculatorRatePage = React.lazy(() => import("./calculator/CalculatorRatePage"));
export const SeventyTwoRulePage = React.lazy(() => import("./calculator/SeventyTwoRulePage"));
export const CalculatorTradePage = React.lazy(() => import("./calculator/CalculatorTradePage"));
// export const CalculatorExchagePage = React.lazy(
//   () => import("./calculator/CalculatorExchagePage")
// );
export const CalculatorValuePage = React.lazy(() => import("./calculator/CalculatorValuePage"));

// Knowledge
export const KnowledgeListPage = React.lazy(() => import("./knowledge/KnowledgeListPage"));
export const KnowledgeViewPage = React.lazy(() => import("./knowledge/KnowledgeViewPage"));
export const KnowledgeEditPage = React.lazy(() => import("./knowledge/KnowledgeEditPage"));

// Site
export const SiteListPage = React.lazy(() => import("./site/SiteListPage"));

// Login
export const LoginPage = React.lazy(() => import("./Login"));

// Not Found
export const NotFoundPage = React.lazy(() => import("./NotFound"));

const routes: RouteObject[] = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: "company",
    element: <Outlet />,
    children: [
      { index: true, element: <CompanySearchPage /> },
      { path: ":code", element: <CompanyViewPage /> },
      { path: "edit/:code", element: <CompanyEditPage /> },
    ],
  },
  {
    path: "theme",
    element: <Outlet />,
    children: [
      { index: true, element: <ThemeListPage /> },
      { path: "edit", element: <ThemeEditPage /> },
      { path: "edit/:id", element: <ThemeEditPage /> },
    ],
  },
  {
    path: "shareholder",
    element: <Outlet />,
    children: [{ index: true, element: <ShareHolderListPage /> }],
  },
  {
    path: "monthly",
    element: <MonthlyPage />,
  },
  {
    path: "oil-stock",
    element: <OilStockPage />,
  },
  {
    path: "slider",
    element: <Outlet />,
    children: [{ index: true, element: <SliderPage /> }],
  },
  {
    path: "calc",
    element: <CalculatorPage />,
    children: [
      { index: true, element: <CalculatorViewPage /> },
      { path: "rate", element: <CalculatorRatePage /> },
      { path: "72", element: <SeventyTwoRulePage /> },
      { path: "trade", element: <CalculatorTradePage /> },
      // { path: "exchange", element: <CalculatorExchagePage /> },
      { path: "value", element: <CalculatorValuePage /> },
    ],
  },
  {
    path: "knowledge",
    element: <Outlet />,
    children: [
      { index: true, element: <KnowledgeListPage /> },
      { path: "edit", element: <KnowledgeEditPage /> },
      { path: "edit/:id", element: <KnowledgeEditPage /> },
      { path: ":id", element: <KnowledgeViewPage /> },
    ],
  },
  {
    path: "site",
    element: <Outlet />,
    children: [{ index: true, element: <SiteListPage /> }],
  },
  {
    path: "s-rim",
    element: <Outlet />,
    children: [{ index: true, element: <SRimPage /> }],
  },
  {
    path: "backtest",
    element: <Outlet />,
    children: [{ index: true, element: <BackTestPage /> }],
  },
  {
    path: "my",
    element: <Outlet />,
    children: [{ index: true, element: <MyPage /> }],
  },
  {
    path: "login",
    element: <LoginPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export default routes;
