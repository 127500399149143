import { UINT_REGEX } from "utils/regex";
import { StyledInput } from "./styles";

interface Props {
  value: string | number;
  onChange: (value: string) => void;
}

export default function LengthInput({ value, onChange }: Props) {
  const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let value = ev.target.value;

    if (!value || UINT_REGEX.test(value)) {
      if (value) value = parseInt(value).toString();
      onChange(value);
    }
  };

  return <StyledInput value={value} onChange={changeHandler} />;
}
