import { Checkbox, Icon } from "components/shared";
import ColorBox from "./ColorBox";
import LengthInput from "./LengthInput";
import { StyledButton } from "./styles";
import { MA } from "interfaces/backtest.interface";

interface Props {
  settings: MA[];
  setSettings: React.Dispatch<React.SetStateAction<MA[]>>;
  onSave: () => void;
}

export default function MovingAverageDays({ settings, setSettings, onSave }: Props) {
  /**
   *
   */
  const changeHandler = (type: "length" | "color", index: number, value: string) => {
    setSettings((prevState) => {
      const copy = [...prevState];
      copy[index][type] = value;
      return copy;
    });
  };

  const addHandler = () => {
    setSettings((prevState) => {
      return [...prevState, { color: "#000000", length: "", visible: true }];
    });
  };

  /**
   *
   */
  const removeHandler = (index: number) => {
    setSettings((prevState) => {
      const result = [...prevState].filter((_, i) => index !== i);
      return result;
    });
  };

  /**
   *
   */
  const hiddenHandler = (checked: boolean, index: number) => {
    setSettings((prevState) => {
      const copy = [...prevState];
      copy[index].visible = checked;
      return copy;
    });
  };

  return (
    <div>
      <ul className="mb-2">
        {settings.map(({ color, length, visible }, i) => {
          return (
            <li className="flex items-center space-x-1" key={i}>
              <Checkbox label="" checked={visible} onChange={(ev) => hiddenHandler(ev.target.checked, i)} />
              <ColorBox color={color} onChange={(value) => changeHandler("color", i, value)} />
              <LengthInput value={length} onChange={(value) => changeHandler("length", i, value)} />
              <button tabIndex={-1} onClick={() => removeHandler(i)}>
                <Icon icon={`times`} />
              </button>
            </li>
          );
        })}
      </ul>
      <div className="flex space-x-2 justify-end">
        <StyledButton onClick={addHandler}>Add</StyledButton>
        <StyledButton onClick={onSave}>Save</StyledButton>
      </div>
    </div>
  );
}
