import * as d3 from "d3";
import dayjs from "dayjs";

type D3_SVGElement = d3.Selection<SVGSVGElement, unknown, null, any>;
type Group = d3.Selection<SVGGElement, unknown, null, any>;

export function group(
  svg: D3_SVGElement,
  options?: { transform?: { x: number; y: number }; className?: string; clipPath?: string }
) {
  const g = svg.append("g");

  if (options) {
    if (options.className) {
      g.classed(options.className, true);
    }

    if (options.clipPath) {
      g.attr("clip-path", options.clipPath);
    }

    if (options.transform) {
      g.attr("transform", `translate(${options.transform.x}, ${options.transform.y})`);
    }
  }

  return g;
}

export const finderOfBand = (x: d3.ScaleBand<Date>, date: Date, breaker: Date) => {
  let isBreak = false;
  let result: Date = date;

  while (!isBreak) {
    date = dayjs(date).add(1, "d").toDate();
    if (date.getTime() > breaker.getTime()) isBreak = true;

    if (x(date)) {
      result = date;
      break;
    }
  }

  return result;
};

export type HorizontalOptions = {
  color?: string;
  thick?: number;
};

/**
 * 수평 라인 그리기
 * @param g
 * @param scale
 * @param range
 * @param y
 * @param options
 */
export const horizontal = (g: Group, range: number[], y: number, options: HorizontalOptions = {}) => {
  const color = options.color || "#AAAAAA";
  const thick = options.thick || 0.7;

  g.append("path")
    .datum(range)
    .attr("fill", "none")
    .attr("stroke", color)
    .attr("stroke-width", thick)
    .attr(
      "d",
      d3
        .line<number>()
        .x((d) => d)
        .y(y)
    );
};

export const scaleBandInvert = (scale: d3.ScaleBand<Date>) => {
  const domain = scale.domain();
  const paddingOuter = scale(domain[0]) || 0;
  const eachBand = scale.step();

  return function (value: number) {
    const index = Math.floor((value - paddingOuter) / eachBand);
    return domain[Math.max(0, Math.min(index, domain.length - 1))];
  };
};
