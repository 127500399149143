import Middleware from "components/backtest/Middleware";
import ImportedItem from "components/backtest/ImportedItem";
import { Button, ComboBox } from "components/shared";
import { DataItem, IImportedItem } from "interfaces/backtest.interface";

import { useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { importedItemsState } from "store/backtest";
import { classNameJoin } from "utils/ui.lib";
import Description from "components/backtest/Middleware/Description";
import { toFixedFloat } from "components/backtest/Middleware/calc";

export default function BackTestPage() {
  const ref = useRef<HTMLInputElement | null>(null);

  const [value, setValue] = useState("");
  const [isEnter, setIsEnter] = useState(false);
  const [data, setData] = useState<DataItem[]>([]);
  const [isUpdate, setIsUpdate] = useState(false);

  const [importedItems, setImportedItems] = useRecoilState(importedItemsState);

  /**
   *
   * @param ev
   * @returns
   */
  const dropHandler = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();

    if (!ev.dataTransfer.items) return;

    const items = [...Array.from(ev.dataTransfer.items)].map((item) => item.webkitGetAsEntry());

    const state: IImportedItem[] = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (!item) continue;

      if (item.isFile) {
        const file = ev.dataTransfer.files[i];
        const url = URL.createObjectURL(file);

        state.push({
          name: file.name,
          url,
        });
      }
    }

    if (!state[0]) return;

    setImportedItems((prevState) => [...prevState, ...state]);
    setValue(state[0].name);
    update(state[0].url);
    setIsEnter(false);
  };

  /**
   *
   * @param ev
   * @returns
   */
  const uploadHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (!ev.target.files) return;

    const file = ev.target.files[0];
    const name = file.name;
    const url = URL.createObjectURL(file);
    setImportedItems((prevState) => [...prevState, { name, url }]);
  };

  /**
   *
   * @param ev
   */
  const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValue(ev.target.value);
  };

  const selectHandler = (item: IImportedItem) => {
    setValue(item.name);
    update(item.url);
    setIsUpdate(true);
  };

  /**
   *
   * @param url
   */
  const update = async (url: string) => {
    const response = await fetch(url);
    const text = await response.text();

    let data = text.split(/\n/g);

    const head = data[0];
    const column = head.split(",");
    const indexOfVolume = column.findIndex((c) => c.toLowerCase() === "volume");

    data = data.slice(1, data.length - 1);

    const items: DataItem[] = [];

    for (let i = 0; i < data.length; i++) {
      const item = data[i].split(",");

      if (item.length > 1) {
        const date = item[0];
        const open = toFixedFloat(item[1]);
        const high = toFixedFloat(item[2]);
        const low = toFixedFloat(item[3]);
        const close = toFixedFloat(item[4]);
        const volume = parseInt(item[indexOfVolume]);

        items.push({
          date,
          close,
          high,
          low,
          open,
          volume,
        });
      }
    }

    setData(items);
  };

  const searchedItems = useMemo(() => {
    if (!value) return importedItems;
    return importedItems.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase()));
  }, [value, importedItems]);

  return (
    <div className="w-full h-full">
      <div className="flex items-center justify-between px-6 h-[40px] mb-2">
        <div className="flex w-[400px] space-x-2">
          <ComboBox
            value={value}
            data={searchedItems}
            placeholder="종목 리스트"
            render={(props: IImportedItem) => <ImportedItem {...props} />}
            onSelect={selectHandler}
            onChange={changeHandler}
          />

          <Button tabIndex={-1} onClick={() => ref.current?.click()}>
            업로드
            <input ref={ref} type="file" className="hidden" onChange={uploadHandler} />
          </Button>
        </div>
        <Description />
      </div>

      <div
        className={classNameJoin([
          isEnter ? "bg-neutral-200" : "",
          "w-full h-[calc(100%-48px)] py-6 px-4 overflow-auto",
        ])}
        onDragOver={(ev) => {
          ev.preventDefault();
          setIsEnter(true);
        }}
        onDragLeave={(ev) => {
          ev.preventDefault();
          setIsEnter(false);
        }}
        onDrop={dropHandler}
        onDragEnd={() => false}
      >
        <Middleware data={data} isUpdate={isUpdate} setIsUpdate={() => setIsUpdate(false)} />
      </div>
    </div>
  );
}
