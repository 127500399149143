import { useMemo } from "react";
import { classNameJoin } from "utils/ui.lib";

import "styles/ui/table.css";

export type IField = {
  name: string;
  column: string;
  width?: number;
  align?: "text-center" | "text-left" | "text-right";
  render?: (item: any, i: number) => React.ReactNode;
};

interface Props {
  fields: IField[];
  data: any[];
}

const Table = ({ fields, data }: Props) => {
  const [align, column] = useMemo(() => {
    const align = [];
    const column = [];

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      align.push(field.align || "text-center");
      column.push(field.column);
    }

    return [align, column];
  }, [fields]);

  return (
    <table className="ui-table border-collapse w-full text-sm select-text">
      <colgroup>
        {fields.map(({ width }, i) => (
          <col key={i} width={width || "*"} />
        ))}
      </colgroup>
      <thead className="overflow-hidden sticky top-0">
        <tr>
          {fields.map(({ name }, i) => (
            <th key={i} className="py-1 px-2 font-normal text-neutral-600">
              {name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.length === 0 && (
          <tr className="h-[80px]">
            <td
              className="text-center text-neutral-400"
              colSpan={fields.length}
            >
              값이 없습니다.
            </td>
          </tr>
        )}

        {data.map((d, i) => {
          return (
            <tr key={i}>
              {column.map((key, j) => (
                <td key={j} className={classNameJoin(["p-2", align[j]])}>
                  {fields[j].render ? fields[j].render!(d, i) : d[key] || "-"}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
