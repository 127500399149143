import { Icon } from "components/shared";
import { useState } from "react";
import { StyledTooltip } from "./styles";

export default function Description() {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative">
      <button onClick={() => setIsVisible(!isVisible)}>
        <Icon icon={`circle-info`} />
      </button>

      {isVisible && (
        <StyledTooltip>
          <ul>
            <li>
              <div className="title">골든크로스, 데드크로스</div>
              <div className="content">
                단기 이평선(20 이전)과 장기 이평선(120 이후)이 서로 교차할 때를
                의미 한다. 단기 이평선이 장기 이평선을 상향 교차할때는
                골든크로스, 하향 교차할때는 데드크로스 (60는 중기로 볼 수 있다.)
              </div>
            </li>
          </ul>
        </StyledTooltip>
      )}
    </div>
  );
}
