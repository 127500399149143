import React, { useCallback, useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";
import { Item } from "interfaces/backtest.interface";
import { MARGIN } from "./BackTestGraph";
import styled from "styled-components";
type D3_SVGElement = d3.Selection<SVGSVGElement, unknown, null, any>;

interface Props {
  items: Item[];
  width: number;
  lim: { min: number; max: number };
  setSelection: React.Dispatch<
    React.SetStateAction<{
      start: Date | null;
      end: Date | null;
    }>
  >;
  setSelectionLim: React.Dispatch<
    React.SetStateAction<{
      min: number | null;
      max: number | null;
    }>
  >;
}

const HEIGHT = 36;

const StyledBackTestXRange = styled.div`
  rect.overlay {
    fill: rgba(0, 0, 0, 0.04);
  }
`;

function BackTestXRange({ width, items, lim, setSelection, setSelectionLim }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const svg = useRef<D3_SVGElement | null>(null);
  const storedXLim = useRef<Date[]>([]);

  // brush x축 한계값
  const xlim = useMemo(() => {
    if (items.length === 0) return [new Date(), new Date()];
    return [items[0].x, items[items.length - 1].x];
  }, [items]);

  // brush x축 scale
  const xScale = useMemo(() => {
    const w = width - MARGIN.left - MARGIN.right;
    return d3.scaleTime().domain(xlim).range([0, w]);
  }, [width, xlim]);

  // brush y축 scale (0, 높이)
  const yScale = useMemo(() => d3.scaleLinear().domain([lim.min, lim.max]).range([HEIGHT, 0]), [lim]);

  /**
   * Brush event callback (실시간 업데이트)
   * @param ev
   * @returns
   */
  const updateChart = useCallback(
    function (ev: any) {
      if (!ev.selection || ev.selection.length < 2) {
        console.warn(`selection warning ${JSON.stringify(ev.selection)}`);
        return;
      }

      const [x0, x1] = ev.selection;

      const start = xScale.invert(x0);
      const end = xScale.invert(x1);

      storedXLim.current = [start, end];

      const sIndex = items.findIndex(({ x }) => x.getTime() >= start.getTime());
      const eIndex = items.findIndex(({ x }) => x.getTime() >= end.getTime());

      if (sIndex !== -1 && eIndex !== -1) {
        const cut = items.slice(sIndex, eIndex).map(({ y }) => y);
        const min = Math.min(...cut);
        const max = Math.max(...cut);
        setSelectionLim({ min, max });
      }

      setSelection({ start, end });
    },
    [items, xScale, setSelection, setSelectionLim]
  );

  useEffect(() => {
    if (!ref.current || items.length === 0) return;
    if (svg.current) svg.current.remove();

    const WIDTH = width - MARGIN.left - MARGIN.right;

    svg.current = d3.select(ref.current).append("svg").attr("width", width).attr("height", HEIGHT);

    const g = svg.current.append("g").attr("transform", `translate(${MARGIN.left}, 0)`);

    // brush에 차트 표현
    g.append("path")
      .classed("brush-path", true)
      .datum(items)
      .attr("fill", "none")
      .attr("stroke", "#444444")
      .attr("stroke-width", 0.5)
      .attr(
        "d",
        d3
          .line<Item>()
          .x((d) => xScale(d.x))
          .y((d) => yScale(d.y))
      );

    // Brush
    g.call(
      d3
        .brushX()
        .extent([
          [0, 0],
          [WIDTH, HEIGHT],
        ])
        .on("brush", updateChart)
    );

    const [x0, x1] = storedXLim.current.length >= 2 ? storedXLim.current : xlim;

    // brush 범위 설정
    g.call(d3.brushX().move, [xScale(x0), xScale(x1)]);
  }, [width, items, xScale, yScale, xlim, updateChart]);

  return (
    <StyledBackTestXRange
      className="w-full"
      ref={ref}
      onContextMenu={(ev: React.MouseEvent<HTMLDivElement>) => {
        ev.preventDefault();
        return false;
      }}
    />
  );
}

export default React.memo(BackTestXRange);
