import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useURLSchema = (keys: string[]) => {
  const navigate = useNavigate();
  const location = useLocation();

  const schema = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const replace = (key: string, value: string) => {
    if (!value) {
      schema.delete(key);
    } else {
      schema.set(key, value);
    }

    navigate({
      search: schema.toString(),
    });
  };

  const search = useMemo(() => {
    const newValue: { [key in string]: string } = {};
    for (let i = 0; i < keys.length; i++) {
      const value = schema.get(keys[i]);
      if (value) {
        newValue[keys[i]] = value;
      }
    }

    return newValue;
  }, [schema, keys]);

  return {
    search,
    replace,
    queryString: schema.toString(),
  };
};

export default useURLSchema;
