import { IMetaResponse } from "interfaces/common.interface";
import fetch from "./config";
import {
  IGetMaterialParams,
  IMaterialsPriceHistories,
} from "interfaces/material.interface";

export const getMaterialPriceHistories = (params: IGetMaterialParams) =>
  fetch.get<IMetaResponse<IMaterialsPriceHistories[]>>(
    `/materials-history/${params.sector}`
  );
