import styled from "styled-components";

export const StyledColor = styled.div`
  width: 10px;
  height: 10px;
  cursor: pointer;

  input {
    width: 0;
    height: 0;
  }
`;

export const StyledInput = styled.input`
  width: 35px;
  padding: 0 4px;
  border: 1px solid #eee;
  border-radius: 2px;
  text-align: right;
  outline: none;
`;

export const StyledButton = styled.button`
  background-color: #eee;
  padding: 2px 6px;
`;

export const StyledTooltip = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  padding: 6px 10px;
  border: 1px solid #000;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  word-break: keep-all;
  min-width: 450px;
  max-width: 700px;

  .title {
    font-size: 13px;
    font-weight: 700;
  }

  .content {
    margin-top: 2px;
    font-size: 12px;
    line-height: 1.3;
  }
`;
