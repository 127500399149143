import { Thumbnail } from "components/shared";
import { ICompanyWithAttribute } from "interfaces/company.interface";
import { classNameJoin, statusName } from "utils/ui.lib";

interface Props extends ICompanyWithAttribute {}

const CompanyBasic = ({ name, market, delisting, status }: Props) => {
  return (
    <>
      <div className="flex items-start justify-between pt-2 pb-4 mb-4 border-b border-b-neutral-300">
        <div className="flex items-center">
          <Thumbnail />

          <div className="ml-4">
            <div
              className={classNameJoin([
                "font-semibold",
                market === 1 ? "text-rose-600" : "text-slate-500",
              ])}
            >
              {market === 1 ? "코스피" : "코스닥"}
            </div>
            <div>
              <span className="font-bold text-xl">{name}</span>
            </div>
          </div>
        </div>
        <p className="text-sm text-rose-700">
          {delisting === 1 ? "* 상장폐지" : ""}
        </p>
      </div>
      <div className="pb-4">
        <ul className="flex text-[10px] space-x-2">
          {Object.keys(status).map((key) => {
            if (status[key as keyof typeof status] === 1) {
              return (
                <li
                  className="bg-rose-500 px-1 h-[16px] leading-[16px] text-white"
                  key={key}
                >
                  {statusName[key as string]}
                </li>
              );
            }

            return null;
          })}
        </ul>
      </div>
    </>
  );
};

export default CompanyBasic;
