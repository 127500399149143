import { useEffect, useRef } from "react";
import { classNameJoin } from "utils/ui.lib";
import EmptyThumbnail from "../../assets/empty_thumbnail.png";

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  size?: number;
  imgClassName?: string;
}

export default function Thumbnail({
  className,
  imgClassName,
  src,
  size = 60,
  style,
  ...props
}: IProps) {
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.onerror = () => {
        imageRef.current!.src = EmptyThumbnail;
      };
    }
  }, []);

  return (
    <div
      className={classNameJoin([
        "bg-white rounded-[50%] overflow-hidden border border-slate-200 hover:border-slate-500 transition-all",
        className,
      ])}
      style={{ ...style, width: size, height: size }}
    >
      <img
        ref={imageRef}
        className={classNameJoin(["w-full h-full object-cover", imgClassName])}
        src={src || EmptyThumbnail}
        alt={props.alt}
        {...props}
      />
    </div>
  );
}
