import { getOneCompany, getOneCompanyAllValues } from "api/company";
import { Progress } from "components/shared";
import CompanyView from "containers/company/CompanyView";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { userIdState } from "store/user";
import { useAlert } from "utils/helper";
import { priceToText } from "utils/lib";
import { classNameJoin } from "utils/ui.lib";

interface IProps {
  toggle: boolean;
  code: string | null;
  onClose: () => void;
}

export default function CompanyPopup({ toggle, code, onClose }: IProps) {
  const userId = useRecoilValue(userIdState);
  const alert = useAlert();

  const { data, isLoading } = useQuery(
    ["company-popup-view", code, userId],
    async () => {
      if (!code || userId === -1) return null;

      const { data: companyData } = await getOneCompany(code);
      const { data: valuesData } = await getOneCompanyAllValues(code);

      if (!companyData.data) return null;
      if (!valuesData.data) return null;

      const company = companyData.data;
      const cap = priceToText(company.cap || "");

      return {
        values: valuesData.data,
        company: {
          ...company,
          cap,
          content: !company.content
            ? "내용없음"
            : company.content.replace(/\n/g, "<br/>"),
          listing_at: dayjs(company.listing_at).format("YYYY년 MM월 DD일"),
          updated_at: dayjs(company.updated_at).format("YYYY년 MM월 DD일"),
        },
      };
    },
    {
      onError(err: any) {
        alert.error(err.message);
      },
    }
  );

  return (
    <div
      className={classNameJoin([
        "fixed top-0 left-0 right-0 bottom-0 z-20 transition-all duration-300",
        toggle ? "opacity-100 visible" : "opacity-0 invisible",
      ])}
    >
      <div
        className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60 z-30"
        onClick={onClose}
      />
      <div
        className={classNameJoin([
          "fixed w-[90%] top-[3vh] bottom-[3vh] left-[50%] translate-x-[-50%] rounded-2xl bg-white z-40 transition-all duration-300 overflow-auto",
          toggle ? "translate-y-0" : "translate-y-[40px]",
        ])}
      >
        <div className="flex justify-end absolute right-0 top-0">
          <button
            className="w-[40px] h-[40px] leading-[40px] bg-neutral-900 text-white font-light text-[32px]"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <div className="p-4 sm:p-6">
          {isLoading ? (
            <Progress />
          ) : (
            <CompanyView userId={userId} data={data} code={code} />
          )}
        </div>
      </div>
    </div>
  );
}
