import { upload } from "api/service";
import { useCallback } from "react";

const useUpload = () => {
  const uploadHandler = useCallback(
    async (files: (File | null | undefined)[]) => {
      const fd = new FormData();
      if (files.length === 0) return null;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!file) continue;
        fd.append("aws", file);
      }

      try {
        const { data } = await upload(fd);
        if (!data.data) return null;
        if (data.data.url.length === 0) return null;
        return data.data;
      } catch (err: any) {
        throw new Error(err);
      }
    },
    []
  );

  return uploadHandler;
};

export default useUpload;
