import { getDartFinState } from "api/company";
import { Progress } from "components/shared";
import Selector from "components/shared/Selector";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useAlert } from "utils/helper";
import { comma, priceToText, toNumber } from "utils/lib";

interface IProps {
  share?: string;
  corpCode?: string | null;
  tclp?: string;
}

export default function FinState({ corpCode, share, tclp }: IProps) {
  const alert = useAlert();
  const [year, setYear] = useState<string | null>(
    dayjs().subtract(1, "year").year().toString()
  );

  const { data, isLoading, isError } = useQuery(
    ["fin-state", corpCode, year],
    () => {
      if (!corpCode) return null;
      return getDartFinState(corpCode, { year })
        .then(({ data }) => {
          if (!data.data) return;

          setYear(data.data.year);

          return data.data;
        })
        .catch((err) => {
          alert.error(err.message);
        });
    }
  );

  const { businessValue, assetsValue, total } = useMemo(() => {
    if (!data) {
      return {
        businessValue: 0,
        assetsValue: 0,
        total: 0,
      };
    }

    const { netIncomes, liquidAssets, liquidDebt, fixedAssets, fixedDebt } =
      data;

    const sum = netIncomes.reduce((p, c) => p + c, 0); // 영업이익 합
    const businessValue = Math.floor((sum / 3) * 10);
    const assetsValue = Math.floor(
      (liquidAssets - liquidDebt) * 1.2 + fixedAssets
    );

    const total = Math.ceil(
      (businessValue + assetsValue - fixedDebt) / toNumber(share || "1")
    );

    return {
      businessValue,
      assetsValue,
      total,
    };
  }, [data, share]);

  const differ = useMemo(() => {
    const current = toNumber(tclp || "0");
    return parseFloat((current / total).toFixed(2));
  }, [tclp, total]);

  const yearOptions = useMemo(() => {
    const currentYear = dayjs().subtract(1, "year").year();
    const years = new Array(10).fill(undefined).map((_, i) => {
      const value = (currentYear - i).toString();

      return {
        label: value,
        value,
      };
    });
    return years;
  }, []);

  return (
    <div className="py-8">
      <h5 className="flex font-bold text-lg mr-2 justify-between items-center">
        <div>
          <span>적정주가 ({year}Y)</span>
          {isLoading && <Progress size={12} />}
        </div>

        <div className="w-[100px]">
          <Selector
            className="text-sm"
            value={year}
            items={yearOptions}
            onChange={(year) => setYear(year)}
          />
        </div>
      </h5>

      <div className="text-sm text-neutral-500">
        * 년도에 따라 적정주가가 표시되지 않을 수 있습니다.
      </div>

      {isError || !data ? (
        <div className="text-rose-600 text-center py-8">
          {year}년도 적정주가를 가져올 수 없습니다.
        </div>
      ) : (
        <>
          <div className="py-4 flex flex-col justify-center items-center space-x-0 space-y-2 text-center md:text-left md:flex-row md:space-x-4 md:space-y-0 md:py-8">
            <div>
              <div className="text-xs">사업가치(원)</div>
              <div>{priceToText(businessValue)}</div>
            </div>

            <div>&#43;</div>

            <div>
              <div className="text-xs">자산가치(원)</div>
              <div>{priceToText(assetsValue)}</div>
            </div>

            <div>&#8722;</div>

            <div>
              <div className="text-xs">[고정]부채(원)</div>
              <div>{priceToText(data.fixedDebt)}</div>
            </div>

            <div>&#247;</div>

            <div>
              <div className="text-xs">상장주식수(개)</div>
              <div>{comma(share)}</div>
            </div>

            <div>&#61;</div>

            <div className="font-bold text-lg">
              <div>{comma(total)} 원</div>
            </div>
          </div>

          <div className="text-center space-y-2">
            <div className="text-sm font-bold">
              <span className="text-indigo-600">현재가격</span>
              <span>과의</span>
              <span className="ml-1 text-emerald-500">괴리율</span>
            </div>

            <div className="space-x-2">
              <span className="text-indigo-600">{comma(tclp)} 원</span>
              <span>&#247;</span>
              <span>{comma(total)} 원</span>
              <span>&#61;</span>
              <span className="text-emerald-500">{differ} 배</span>
            </div>

            <div className="text-xs text-neutral-500">
              (1배 이상일 경우 고평가 상태임)
            </div>
          </div>

          <div className="mt-10">
            <table className="values-table">
              <thead>
                <tr>
                  <th>구분</th>
                  <th>당기</th>
                  <th>전기</th>
                  <th>전전기</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1. 유동자산</td>
                  {data.liquidAssetsItems.map((d, i) => {
                    return <td key={i}>{priceToText(d)}</td>;
                  })}
                </tr>
                <tr>
                  <td>2. 유동부채</td>
                  {data.liquidDebtItems.map((d, i) => {
                    return <td key={i}>{priceToText(d)}</td>;
                  })}
                </tr>
                <tr>
                  <td> = 유동비율</td>
                  {data.liquidAssetsItems.map((a, i) => {
                    const b = data.liquidDebtItems[i];
                    return <td key={i}>{((a / b) * 100).toFixed(2)}%</td>;
                  })}
                </tr>
                <tr>
                  <td>비유동자산</td>
                  {data.fixedAssetsItems.map((d, i) => {
                    return <td key={i}>{priceToText(d)}</td>;
                  })}
                </tr>
                <tr>
                  <td>비유동부채</td>
                  {data.fixedDebtItems.map((d, i) => {
                    return <td key={i}>{priceToText(d)}</td>;
                  })}
                </tr>
                <tr>
                  <td>영업이익</td>
                  {data.netIncomes.map((d, i) => {
                    return <td key={i}>{priceToText(d)}</td>;
                  })}
                </tr>
                <tr>
                  <td>현금흐름</td>
                  {data.incomeCashFlow.map((d, i) => {
                    return <td key={i}>{priceToText(d)}</td>;
                  })}
                </tr>
                <tr>
                  <td>이자지급</td>
                  {data.payInterest.map((d, i) => {
                    return <td key={i}>{priceToText(d)}</td>;
                  })}
                </tr>
                <tr>
                  <td>재고자산</td>
                  {data.inventoryAsset.map((d, i) => {
                    return <td key={i}>{priceToText(d)}</td>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}
