import fetch from "./config";
import {
  TFilterParams,
  ISearchWithPagination,
  IResponse,
  IMetaResponse,
} from "interfaces/common.interface";
import {
  ICompanyDynamic,
  ICompanyPutData,
  ICompanyWithAttribute,
  IStatistics,
  ISearchCompany,
  ISectors,
  IValueWithPrice,
} from "interfaces/company.interface";
import {
  IDartFinState,
  IDartListPage,
  IDartNotice,
  IDartParams,
  ITargetPrice,
} from "interfaces/dart.interface";

export const getFindCompany = (
  params: ISearchWithPagination & { excludeDelete?: boolean }
) => fetch.get<IResponse<ISearchCompany[]>>(`/company`, { params });

export const getOneCompany = (code: string) =>
  fetch.get<IResponse<ICompanyWithAttribute>>(`/company/${code}`);

export const getSector = () =>
  fetch.get<IResponse<ISectors>>(`/company/sector`);

export const updateCompany = (code: string, data: ICompanyPutData) =>
  fetch.put(`/company/${code}`, data);

export const getFindDynamic = (params: TFilterParams) =>
  fetch.get<IResponse<ICompanyDynamic[]>>(`/company/dynamic`, { params });

export const getOneCompanyAllValues = (code: string) =>
  fetch.get<IResponse<IValueWithPrice[]>>(`/company/values/${code}`);

export const updateFavorit = (code: string, favorit: 0 | 1) =>
  fetch.put(`/company/favorit/${code}`, { favorit });

export const getDartNotice = (corp_code: string, params: IDartParams) =>
  fetch.get<IResponse<IDartListPage<IDartNotice>>>(
    `/company/dart/${corp_code}/notice`,
    {
      params,
    }
  );

export const getDartFinState = (
  corp_code: string,
  params: { year: string | null }
) =>
  fetch.get<IResponse<IDartFinState>>(`/company/dart/${corp_code}/finstate`, {
    params,
  });

export const getStatistics = () =>
  fetch.get<IResponse<{ year: number[]; items: IStatistics[] }>>(
    `/company/statistics`
  );

export const getTargetPrice = ({
  search,
  limit,
  page,
  category,
}: ISearchWithPagination & { category?: string }) =>
  fetch.get<IMetaResponse<{ data: ITargetPrice[]; avg: number }>>(
    `/company/target-price`,
    {
      params: {
        limit,
        page,
        search,
        category,
      },
    }
  );
