//  Float 형식 (음수 포함) onChange pass 용
export const FLOAT_REGEX_ON_CHANGE = /^-?[0-9]+\.?[0-9]*$/;

//  Float 형식 (음수 포함)
export const FLOAT_REGEX = /^-?[0-9]+\.?[0-9]+$/;

//  Float 형식 (양수만 가능)
export const UFLOAT_REGEX = /^[0-9]+\.?[0-9]+$/;

// Int 형식 (양수만)
export const UINT_REGEX = /^[0-9]+$/;
