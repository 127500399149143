import { useMemo } from "react";
import { classNameJoin } from "utils/ui.lib";
import Icon from "./Icon";

interface Props {
  page: number | string;
  limit: number | string;
  count?: number;
  onPage: (page: number) => void;
}

const Pagination = ({ page = 1, limit = 30, count = 0, onPage }: Props) => {
  const block = 5;

  const pageHandler = (page: number) => {
    onPage(page);
  };

  const { pages, pageCount, blocks, currentBlock } = useMemo(() => {
    const pages = Math.ceil(count / parseInt(limit.toString()));
    const blocks = Math.ceil(pages / block);
    const currentBlock = Math.ceil(parseInt(page.toString()) / block);
    const currentFullPage = currentBlock * block;
    const pageCount = currentFullPage > pages ? pages % block : block;

    return { pages, pageCount, blocks, currentBlock };
  }, [limit, count, page]);

  if (page > pages) return null;

  const _page = parseInt(page.toString());

  return (
    <div className="flex items-center space-x-2">
      <Arrow disabled={currentBlock === 1} onClick={() => onPage(1)}>
        <Icon icon="angles-left" fontSize={14} />
      </Arrow>
      <Arrow disabled={page === 1} onClick={() => onPage(_page - 1)}>
        <Icon icon="angle-left" fontSize={14} />
      </Arrow>
      <ul className="flex items-center space-x-2 text-sm">
        {Array.from(new Array(pageCount)).map((_, i) => {
          const num = i + 1 + block * (currentBlock - 1);

          return (
            <li
              className={classNameJoin([
                "px-2 cursor-pointer h-[24px] leading-[24px]",
                page === num
                  ? "text-neutral-900 font-semibold"
                  : "text-neutral-400",
              ])}
              key={i}
              onClick={() => pageHandler(num)}
            >
              {num}
            </li>
          );
        })}
      </ul>
      <Arrow disabled={page === pages} onClick={() => onPage(_page + 1)}>
        <Icon icon="angle-right" fontSize={14} />
      </Arrow>
      <Arrow disabled={currentBlock === blocks} onClick={() => onPage(pages)}>
        <Icon icon="angles-right" fontSize={14} />
      </Arrow>
    </div>
  );
};

const Arrow = ({
  children,
  disabled,
  onClick,
}: React.PropsWithChildren<{ disabled: boolean; onClick: () => void }>) => {
  return (
    <button
      className="w-[24px] h-[24px] text-neutral-900 disabled:text-neutral-400"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Pagination;
