import dayjs from "dayjs";

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const DATE = HOUR * 24;

export const statusName: { [key: string]: string } = {
  ins_discls: "불성실공시",
  ivst_caution: "투자주의",
  ivst_remind_isu: "투자주의환기",
  ivst_warn: "투자경고",
  st_overheat_isu: "단기과열 종목",
  trd_clean: "정리매매",
  trd_manage: "관리종목",
  trd_stop: "매매거래 중지",
  trd_unit: "상장주식수 부족 우선주",
  vlwliqu_valu: "단일가매매대상 초저 유동성",
};

export const classNameJoin = (
  classNames: (string | undefined | null | boolean)[]
) => classNames.filter((className) => !!className).join(" ");

export const copy = (value: string) => {
  return navigator.clipboard.writeText(value);
};

export const nl2br = (html: string) => html.replace(/\n/g, "<br/>");

export const daysFormat = (
  date: string,
  format: string = "YYYY.MM.DD HH:mm:ss"
) => {
  const millisecond = dayjs(date).valueOf();
  const subtract = dayjs().subtract(millisecond, "millisecond").valueOf();

  if (subtract / MINUTE < 60) {
    return Math.floor(subtract / MINUTE) + "분 전";
  }

  if (subtract / HOUR < 24) {
    return Math.floor(subtract / HOUR) + "시간 전";
  }

  if (subtract / DATE < 7) {
    return Math.floor(subtract / DATE) + "일 전";
  }

  return dayjs(date).format(format);
};

export const minmax = (
  value: number | string | undefined | null,
  min: number,
  max: number
) => {
  const num = parseFloat((value || "0").toString());

  if (isNaN(num)) return "";

  return num > max
    ? "bg-indigo-50 text-indigo-700"
    : num <= min
    ? "bg-rose-50 text-rose-600"
    : "";
};

export const maxmin = (
  value: number | string | undefined | null,
  max: number,
  min: number
) => {
  const num = parseFloat((value || "0").toString());

  if (isNaN(num)) return "";

  return num > max
    ? "bg-rose-50 text-rose-600"
    : num <= min
    ? "bg-indigo-50 text-indigo-700"
    : "";
};

export const goBlank = (url: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.click();
  a.remove();
};
