import { IResponse } from "interfaces/common.interface";
import fetch from "./config";
import {
  ISigninPostData,
  IToken,
  IUserProperty,
} from "interfaces/user.interface";

export const login = (data: ISigninPostData) =>
  fetch.post<IResponse<IToken>>("/user/signin", data);

export const property = () =>
  fetch.get<
    IResponse<
      {
        user: IUserProperty;
      } & IToken
    >
  >("/user/property");
