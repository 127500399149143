import { useMemo } from "react";
import { IValueWithPrice } from "interfaces/company.interface";
import { comma } from "utils/lib";
import { classNameJoin, goBlank, minmax } from "utils/ui.lib";

interface Props {
  data: IValueWithPrice[];
  tclp: string;
}

const values = [
  { label: "매출액", key: "REVENUE" },
  { label: "매출원가", key: "COST" },
  {
    label: "매출 총 이익(마진)",
    key: "REVENUE_PROFIT",
    subLabel: "전년 대비 증가율",
  }, // GROSS PROFITS
  {
    label: "매출 총 이익률 ",
    key: "REVENUE_RATIO",
    prefix: "%",
  },
  { label: "영업이익", key: "PROFIT" },
  { label: "당기순이익", key: "INCOME" },
  { label: "영업이익률", key: "OPM", prefix: "%" },
  { label: "순이익률", key: "NPM", prefix: "%" },
  { label: "자산총계", key: "ASSETS" },
  { label: "부채총계", key: "DEBT" },
  { label: "부채비율", key: "DEBT_RATIO" },
  { label: "자본총계", key: "EQUITY" },
  { label: "자본금", key: "CAPITAL" },
  { label: "PER (Live)", key: "PER" },
  { label: "PBR (Live)", key: "PBR" },
  { label: "PCR (Live)", key: "PCR" },
  { label: "PSR (Live)", key: "PSR" },
  { label: "ROA", key: "ROA" },
  { label: "ROE", key: "ROE", link: "/knowledge/37?page=1&q=roe" },
  { label: "EPS", key: "EPS" },
  { label: "BPS", key: "BPS" },
  { label: "CPS", key: "CPS" },
  { label: "SPS", key: "SPS" },
  { label: "EBITDA", key: "EBITDA" },
  { label: "EV", key: "EV" },
  { label: "EV/EBITDA", key: "EV/EBITDA" },
  { label: "GP/A", key: "GP/A" },
  { label: "잉여현금흐름(FCF)", key: "FCF" },
  { label: "자본적지출", key: "CAFEX" },
  { label: "현금 DPS", key: "DPS" },
  { label: "현금배당수익률", key: "DY", prefix: "%" },
  { label: "현금배당성향(%)", key: "DPR", prefix: "%" },
  { label: "DER", key: "DER" },
];

const ValueTable = ({ data, tclp }: Props) => {
  const years = useMemo(
    () => data.map(({ year, month }) => ({ year, month })),
    [data]
  );

  const linkHandler = (link: string | undefined) => {
    if (!link) return;
    goBlank(link);
  };

  return (
    <div className="py-8">
      <h5 className="inline-block font-bold text-lg">가치평가</h5>
      <div className="py-4 overflow-auto">
        <table className="values-table">
          <thead>
            <tr>
              <th rowSpan={2}></th>
              <th colSpan={years.length}>연간</th>
            </tr>
            <tr>
              {years.map(({ year, month }, i) => (
                <th key={i}>
                  {year}.{month}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {values.map(({ label, subLabel, key, prefix, link }) => {
              return (
                <tr key={key}>
                  <td className="bg-neutral-100 sticky left-0">
                    <div>
                      <button onClick={() => linkHandler(link)}>{label}</button>
                    </div>
                    <div className="text-[10px]">{subLabel}</div>
                  </td>

                  {years.map((_, i) => {
                    const preValues = data[i + 1]; // 이전 년도 데이터

                    const values = data[i] as { [key in string]: any };
                    const value = values[key.toLowerCase()] || "-";
                    const className =
                      key === "ROE" || key === "ROA" || key === "OPM"
                        ? minmax(value, 0, 15)
                        : parseInt(value) < 0
                        ? "bg-rose-50 text-rose-600"
                        : "";

                    if (key === "COST") {
                      const value = comma(
                        (values["revenue"] - values["revenue_profit"]).toFixed(
                          0
                        )
                      );

                      return <td key={i}>{value}</td>;
                    }

                    if (key === "REVENUE_RATIO") {
                      const value =
                        (values["revenue_profit"] / values["revenue"]) * 100;
                      return <td key={i}>{value.toFixed(2)} %</td>;
                    }

                    if (key === "DEBT_RATIO") {
                      const value = (values["debt"] / values["assets"]) * 100;
                      return <td key={i}>{value.toFixed(2)} %</td>;
                    }

                    if (key === "GP/A") {
                      return (
                        <td className={className} key={i}>
                          {values["gp_a"]}
                        </td>
                      );
                    }

                    if (key === "PER") {
                      const live = (parseInt(tclp) / values["eps"]).toFixed(2);
                      return (
                        <td className={className} key={i}>
                          {value} ({live})
                        </td>
                      );
                    }

                    if (key === "PBR") {
                      const live = (parseInt(tclp) / values["bps"]).toFixed(2);
                      return (
                        <td className={className} key={i}>
                          {value} ({live})
                        </td>
                      );
                    }

                    if (key === "PCR") {
                      const live = (parseInt(tclp) / values["cps"]).toFixed(2);
                      return (
                        <td className={className} key={i}>
                          {value} ({live})
                        </td>
                      );
                    }

                    if (key === "PSR") {
                      const live = (parseInt(tclp) / values["sps"]).toFixed(2);
                      return (
                        <td className={className} key={i}>
                          {value} ({live})
                        </td>
                      );
                    }

                    if (key === "EV/EBITDA") {
                      return (
                        <td className={className} key={i}>
                          {values["ev_ebitda"]}
                        </td>
                      );
                    }

                    if (key === "REVENUE_PROFIT") {
                      const preValue =
                        i === years.length - 1
                          ? 0
                          : preValues["revenue_profit"];

                      const symbol =
                        values["revenue_profit"] < preValue ? -1 : 1;

                      const value = Math.floor(
                        values["revenue_profit"] - preValue
                      );

                      return (
                        <td key={i}>
                          <div>{comma(values["revenue_profit"])}</div>
                          <div
                            className={classNameJoin([
                              "text-[10px]",
                              symbol < 0 ? "text-rose-600" : "text-indigo-700",
                            ])}
                          >
                            {comma(value)} 원
                          </div>
                        </td>
                      );
                    }

                    return (
                      <td className={className} key={i}>
                        <span>{comma(value)}</span>
                        {prefix ? (
                          <span className="ml-1 text-xs">{prefix}</span>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ValueTable;
