import { getMaterialPriceHistories } from "api/materials";
import Checkbox from "./Checkbox";
import { useAlert } from "utils/helper";
import { useState } from "react";
import { IMaterialsPack, MaterialKey } from "interfaces/material.interface";

interface Props {
  onChange: (data: IMaterialsPack) => void;
}

const KEY: MaterialKey[] = [
  "oil",
  "sugar",
  "america-rate",
  "usd-krw",
  "nominal",
  "real",
];

export default function Material({ onChange }: Props) {
  const alert = useAlert();

  const [checklist, setChecklist] = useState<number[]>([]);
  const [data, setData] = useState<IMaterialsPack>({});

  const callback = (checklist: number[], data: IMaterialsPack) => {
    const result: IMaterialsPack = {};

    for (let i = 0; i < checklist.length; i++) {
      const key = KEY[checklist[i]];
      result[key] = data[key];
    }

    onChange && onChange(result);
  };

  const changeHandler = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(ev.target.value);

    if (isNaN(value)) return;

    let copy = [...checklist];

    if (!ev.target.checked) {
      // 데이터 제거
      const newState = copy.filter((num) => num !== value);
      setChecklist(newState);
      callback(newState, data);
      return;
    }

    if (data[KEY[value]]) {
      setChecklist([...copy, value]);
      callback([...copy, value], data);
      return;
    }

    try {
      const response = await getMaterialPriceHistories({
        sector: value,
      });

      if (!response.data.data) return;

      const format = response.data.data.map((d) => ({
        ...d,
        value: parseFloat(d.value.toString()),
      }));

      const newData = { ...data, [KEY[value]]: format };
      setData(newData);
      setChecklist([...copy, value]);
      callback([...copy, value], newData);
    } catch (err: any) {
      alert.error(err.message);
    }
  };

  return (
    <div>
      <Checkbox label="원유" value={0} onChange={changeHandler} />
      <Checkbox label="원당" value={1} onChange={changeHandler} />
      <Checkbox label="미국기준금리" value={2} onChange={changeHandler} />
      <Checkbox label="USD/KRW" value={3} onChange={changeHandler} />
      <Checkbox label="GDP 국내총샌산" value={4} onChange={changeHandler} />
      <Checkbox label="GDP 경제성장률" value={5} onChange={changeHandler} />
    </div>
  );
}
