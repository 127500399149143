import { getDartNotice } from "api/company";
import { Input, Pagination, Progress } from "components/shared";
import Selector from "components/shared/Selector";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { usePagination } from "utils/helper";
import { goBlank } from "utils/ui.lib";

interface IProps {
  code: string;
  corpCode?: string | null;
}

export default function DartNotice({ code, corpCode }: IProps) {
  const [rangeDate, setRangeDate] = useState<string | null>("6"); // 몇개월 전까지 ??

  const { page, limit, movePage } = usePagination({});

  const { data, isLoading } = useQuery(
    ["dart-notice", corpCode, page, rangeDate],
    () => {
      if (!corpCode) return null;

      const range = parseInt(rangeDate || "1");

      return getDartNotice(corpCode, {
        start_date: dayjs().subtract(range, "month").format("YYYYMMDD"),
        end_date: dayjs().format("YYYYMMDD"), // 현재날 까지 공시
        page,
        size: limit,
      }).then(({ data }) => {
        if (!data.data) return null;

        const list = (data.data.list || []).map(
          ({ report_nm, rcept_no, rcept_dt }) => {
            return {
              no: rcept_no,
              name: report_nm,
              date: dayjs(rcept_dt).format("YYYY년 MM월 DD일"),
            };
          }
        );

        return {
          ...data.data,
          list,
        };
      });
    }
  );

  const openNotice = (no: string) => {
    goBlank("https://dart.fss.or.kr/dsaf001/main.do?rcpNo=" + no);
  };

  const rangeOptions = useMemo(
    () =>
      new Array(12)
        .fill(undefined)
        .map((_, i) => ({ label: `${i + 1}`, value: `${i + 1}` })),
    []
  );

  const size = useMemo(() => (data ? data.list.length : 0), [data]);

  return (
    <div className="py-8">
      <h5 className="flex font-bold text-lg justify-between items-center">
        <div>
          <span>공시정보</span>
          {isLoading && <Progress size={12} />}
        </div>

        <div className="text-sm w-[80px]">
          <Selector
            value={rangeDate}
            items={rangeOptions}
            onChange={(data) => setRangeDate(data)}
          />
        </div>
      </h5>

      <div className="py-4">
        {!corpCode || !data || size === 0 ? (
          <div className="flex py-10 justify-center items-center text-neutral-400">
            공시정보가 없습니다.
          </div>
        ) : (
          <ul className="space-y-2">
            {data.list.map(({ no, date, name }) => {
              return (
                <li
                  key={no}
                  className="border p-4 rounded-md cursor-pointer hover:bg-neutral-100"
                  onClick={() => openNotice(no)}
                >
                  <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                    {name}
                  </div>
                  <div className="text-sm text-neutral-500">{date}</div>
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <div className="flex justify-center py-6 mb-6">
        <Pagination
          page={data ? data.page_no || 0 : 0}
          count={data ? data.total_count || 0 : 0}
          limit={data ? data.page_count || 0 : 0}
          onPage={(page) => movePage(page)}
        />
      </div>
    </div>
  );
}
