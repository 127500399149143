import React, { useCallback, useMemo, useState } from "react";
import { AlertStatus, IAlert } from "interfaces/common.interface";
import { suid } from "utils/lib";
import Alert from "./components/Alert";

export interface IAlertContext {
  success: (m: string) => void;
  error: (m: string) => void;
  debug: (m: string) => void;
}

export const AlertContext = React.createContext<IAlertContext>(null!);

const AlertProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [alerts, setAlerts] = useState<IAlert[]>([]);

  const show = (status: AlertStatus, message: string) => {
    const alert: IAlert = {
      uid: suid(),
      status,
      message,
    };

    setAlerts((prevState) => {
      return [...prevState, alert];
    });
  };

  const success = useCallback((message: string) => {
    show("success", message);
  }, []);

  const error = useCallback((message: string) => {
    show("error", message);
  }, []);

  const debug = useCallback((message: string) => {
    show("debug", message);
  }, []);

  const hide = useCallback((uid: string) => {
    setAlerts((prevState) => {
      const newState = prevState.filter((alert) => alert.uid !== uid);
      return [...newState];
    });
  }, []);

  const value = useMemo(
    () => ({
      success,
      error,
      debug,
    }),
    [success, error, debug]
  );

  return (
    <AlertContext.Provider value={value}>
      {children}
      <div className="fixed right-0 bottom-0 left-0 z-50">
        <ul className="absolute left-5 bottom-5 space-y-2 flex flex-col items-start">
          {alerts.map(({ uid, status, message }) => (
            <Alert
              key={uid}
              uid={uid}
              status={status}
              message={message}
              onHide={hide}
            />
          ))}
        </ul>
      </div>
    </AlertContext.Provider>
  );
};

export default AlertProvider;
