import { NavLink, useNavigate } from "react-router-dom";
import { Dropdown, Icon } from "./shared";
import { useLocation } from "react-router-dom";

interface Props {
  onLogout: () => void;
}

const routes = [
  {
    path: "/",
    key: "notice",
    name: "홈",
  },
  {
    path: "company",
    key: "company",
    name: "기업정보",
  },
  {
    path: "theme",
    key: "theme",
    name: "테마관리",
  },
  {
    path: "knowledge",
    key: "knowledge",
    name: "지식관리",
  },
  {
    path: "s-rim",
    key: "s-rim",
    name: "S-RIM",
  },
  {
    path: "monthly",
    key: "monthly",
    name: "월 수익률",
  },
  {
    path: "oil-stock",
    key: "oil-stock",
    name: "미국 원유 재고율",
  },
  {
    path: "backtest",
    key: "backtest",
    name: "백테스트",
  },
];

const GlobalNavigationBar = ({ onLogout }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const moveTo = (path: string) => {
    navigate(path);
  };

  if (location.pathname === "/login") {
    return null;
  }

  return (
    <header className="select-none w-full">
      <div className="container px-4 m-auto flex justify-between items-center">
        <ul className="scrollbar-hide h-[68px] flex items-center space-x-6 text-base text-gray-400 font-medium whitespace-nowrap overflow-x-auto mr-4 flex-1">
          {routes.map(({ path, name }, i) => (
            <li key={i}>
              <NavLink
                to={path}
                className={({ isActive }) =>
                  isActive ? "font-bold text-neutral-800" : ""
                }
              >
                {name}
              </NavLink>
            </li>
          ))}
        </ul>
        <div>
          <ul className="flex space-x-2">
            <li className="w-[40px] h-[40px] text-center leading-[40px]">
              <Dropdown
                label={<Icon icon="bars" fontSize={18} />}
                onTrigger={moveTo}
              >
                <Dropdown.Item value="/calc">계산기</Dropdown.Item>
                <Dropdown.Item value="/site">도움 사이트</Dropdown.Item>
                <Dropdown.Item value="/slider">이미지 슬라이더</Dropdown.Item>
                <Dropdown.Item value="/none">CSV 그래프</Dropdown.Item>
                <Dropdown.Item value="/my">사용자</Dropdown.Item>
              </Dropdown>
            </li>
            <li className="w-[40px] h-[40px] text-center leading-[40px]">
              <Icon
                className="cursor-pointer"
                icon="arrow-right-from-bracket"
                fontSize={18}
                onClick={onLogout}
              />
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default GlobalNavigationBar;
