import { useAlert } from "utils/helper";
import { classNameJoin, copy } from "utils/ui.lib";
import {
  ICompanyWithAttribute,
  IValueWithPrice,
} from "interfaces/company.interface";
import ValueTable from "components/company/ValueTable";
import CompanyBasic from "components/company/CompanyBasic";
import { Icon } from "components/shared";
import EmptyData from "components/EmptyData";
import { comma } from "utils/lib";
import DartNotice from "components/company/DartNotice";
import FinState from "components/company/FinState";
import TargetPrice from "components/company/TargetPrice";

interface Props {
  userId: number;
  code?: string | null;
  data?: {
    company: ICompanyWithAttribute;
    values: IValueWithPrice[];
  } | null;
  onMoveToEdit?: () => void;
}

const CompanyView = ({ userId, code, data, onMoveToEdit }: Props) => {
  const alert = useAlert();

  const copyHandler = (value?: string | null) => {
    if (!value) return;

    copy(value)
      .then(() => alert.success("복사가 완료되었습니다."))
      .catch(() => alert.error("복사실패"));
  };

  if (!code || !data) {
    return <EmptyData>찾으려는 데이터가 없습니다.</EmptyData>;
  }

  const {
    corp_code,
    content = "",
    cap,
    share,
    sector,
    wics,
    tclp,
    listing_at,
    updated_at,
  } = data.company;

  return (
    <div>
      <div className="w-full">
        <CompanyBasic {...data.company} />

        <div className="flex flex-col md:flex-row">
          <div className="flex-1 border-neutral-300 border-b mb-4 pb-4 md:pr-4 md:border-r md:border-b-0 md:mb-0 md:pb-0">
            <div className="flex items-center justify-between">
              <div>
                <h5 className="inline-block font-bold text-lg mr-2">
                  기업정보
                </h5>
                {userId === 1 && (
                  <Icon
                    className="cursor-pointer"
                    icon={["far", "edit"]}
                    onClick={onMoveToEdit}
                  />
                )}
              </div>
              <span className="text-neutral-500 text-sm">
                마지막 수정 · {updated_at}
              </span>
            </div>
            <div
              className="py-2 text-neutral-700 text-sm select-text"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            ></div>
          </div>

          <div className="text-sm sm:w-[100%] md:px-4 md:w-[240px]">
            <ul className="space-y-1.5 text-slate-600">
              <li className="flex justify-between">
                <span>종목코드</span>
                <span
                  className="cursor-pointer text-neutral-700"
                  onClick={() => copyHandler(code)}
                >
                  {code}
                  <Icon className="ml-1" icon={["far", "copy"]} fontSize={12} />
                </span>
              </li>

              <li className="flex justify-between">
                <span>기업코드</span>
                <span
                  className="cursor-pointer text-neutral-700"
                  onClick={() => copyHandler(corp_code)}
                >
                  {corp_code || "비어있음"}
                  <Icon className="ml-1" icon={["far", "copy"]} fontSize={12} />
                </span>
              </li>

              <li className="flex justify-between">
                <span>시가총액</span>
                <span>{cap}</span>
              </li>

              <li className="flex justify-between">
                <span>주식수</span>
                <span>{comma(share)}</span>
              </li>

              <li className="flex justify-between">
                <span>분류</span>
                <span>{sector}</span>
              </li>

              <li className="flex justify-between">
                <span>상세분류</span>
                <span>
                  <span
                    className={classNameJoin([
                      "ml-2",
                      !wics && "text-neutral-500",
                    ])}
                  >
                    {wics || "분류없음"}
                  </span>
                </span>
              </li>

              <li className="flex justify-between">
                <span>상장일</span>
                <span>{listing_at}</span>
              </li>

              <li className="flex justify-between">
                <span>WISE 리포트</span>
                <span>
                  <a
                    href={`https://comp.wisereport.co.kr/company/c1080001.aspx?cmp_cd=${code}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    링크 <Icon icon="arrow-up-right-from-square" />
                  </a>
                </span>
              </li>
              <li className="flex justify-between">
                <span>네이버 링크</span>
                <span>
                  <a
                    href={`https://finance.naver.com/item/main.naver?code=${code}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    링크 <Icon icon="arrow-up-right-from-square" />
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <FinState corpCode={corp_code} share={share} tclp={tclp} />
      <TargetPrice code={code} tclp={tclp} />
      <ValueTable data={data.values} tclp={tclp} />
      <DartNotice code={code} corpCode={corp_code} />
    </div>
  );
};

export default CompanyView;
