import { IUserProperty } from "interfaces/user.interface";
import { atom, selector } from "recoil";

export const isLoginState = atom<boolean>({
  key: "loginState",
  default: false,
});

export const userPropertyState = atom<IUserProperty | null>({
  key: "userPropertyState",
  default: null,
});

export const userIdState = selector({
  key: "userIdState",
  get({ get }) {
    const user = get(userPropertyState);
    if (!user) return -1;
    return user.id;
  },
});
