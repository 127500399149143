import { classNameJoin } from "utils/ui.lib";

interface IProps extends React.HTMLAttributes<HTMLLabelElement> {}

export default function Label({
  children,
  className,
  ...props
}: React.PropsWithChildren<IProps>) {
  return (
    <label
      className={classNameJoin([
        "select-none inline-block text-sm text-neutral-500 mb-1",
        className,
      ])}
      {...props}
    >
      {children}
    </label>
  );
}
