import { classNameJoin } from "utils/ui.lib";

const PageLayout = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={classNameJoin(["container mx-auto p-4", className])}>
      {children}
    </div>
  );
};

export default PageLayout;
