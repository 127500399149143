import React, { useState } from "react";
import CompanyPopup from "./components/ComapnyPopup";

export interface ICompanyPopupContext {
  show: (code: string) => void;
}

export const CompanyPopupContext = React.createContext<ICompanyPopupContext>(
  null!
);

export default function CompanyPopupProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [toggle, setToggle] = useState<boolean>(false);
  const [code, setCode] = useState<string | null>(null);

  const show = (code: string) => {
    setToggle(true);
    setCode(code);
  };

  const hide = () => {
    setToggle(false);

    setTimeout(() => {
      setCode(null);
    }, 500);
  };

  const value = {
    show,
  };

  return (
    <CompanyPopupContext.Provider value={value}>
      {children}
      <CompanyPopup toggle={toggle} code={code} onClose={hide} />
    </CompanyPopupContext.Provider>
  );
}
