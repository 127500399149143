import PageLayout from "layouts/PageLayout";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { classNameJoin } from "utils/ui.lib";

const category = [
  {
    path: "/calc",
    name: "복리계산기",
  },
  {
    path: "/calc/72",
    name: "72법칙",
  },
  {
    path: "/calc/rate",
    name: "이익률",
  },
  {
    path: "/calc/trade",
    name: "모의매매",
  },
  // {
  //   path: "/calc/exchange",
  //   name: "환율계산",
  // },
  {
    path: "/calc/value",
    name: "기업가치",
  },
];

export default function CalculatorPage() {
  const { pathname } = useLocation();

  return (
    <PageLayout>
      <ul className="flex mt-2 mb-6 flex-wrap">
        {category.map(({ path, name }) => {
          return (
            <NavLink
              className={() =>
                classNameJoin([
                  "text-sm py-1.5 px-3 m-1 rounded-md whitespace-nowrap",
                  pathname === path
                    ? "bg-neutral-700 text-white"
                    : "bg-neutral-200 text-neutral-500",
                ])
              }
              to={path}
              key={name}
            >
              <li>{name}</li>
            </NavLink>
          );
        })}
      </ul>
      <Outlet />
    </PageLayout>
  );
}
