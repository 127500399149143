import { useContext, useMemo } from "react";
import { classNameJoin } from "utils/ui.lib";
import { RadioContext } from "./RadioGroup";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export default function Radio({
  children,
  ...props
}: React.PropsWithChildren<IProps>) {
  const context = useContext(RadioContext);

  const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
    context && context.onActive(ev.target.value);
  };

  const checked = useMemo(() => {
    return context === null ? props.checked : context.active === props.value;
  }, [props, context]);

  const name = useMemo(() => {
    return context === null ? props.name : context.name;
  }, [props, context]);

  return (
    <label
      className={classNameJoin([
        "flex-1 select-none overflow-hidden whitespace-nowrap first:rounded-tl-md first:rounded-bl-md last:rounded-tr-md last:rounded-br-md",
      ])}
    >
      <input
        {...props}
        className="hidden"
        type="radio"
        name={name}
        checked={checked}
        onChange={changeHandler}
      />
      <span
        className={classNameJoin([
          "text-sm py-1.5 px-5 flex cursor-pointer justify-center",
          checked
            ? "bg-neutral-700 text-white"
            : "bg-neutral-100 text-neutral-700",
        ])}
      >
        {children}
      </span>
    </label>
  );
}
