import axios from "axios";
import env from "config/env";
import { hasOwnProperty } from "utils/lib";
import errorPackage from "./error.package";

const fetch = axios.create({
  baseURL: env.BASE_URL,
});

fetch.interceptors.request.use(
  function (request) {
    return request;
  },
  function (error: any) {
    throw new Error(error);
  }
);

fetch.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: any) {
    let response = null;
    let statusCode = null;
    let statusText = null;

    if (!error.response) {
      throw {
        statusCode: 500,
        message: "알 수 없는 오류 발생",
      };
    }

    if (hasOwnProperty(error.response, "statusText")) {
      const { statusText: text, status } = error.response;

      statusText = text;
      statusCode = status;
    }

    if (error.response.data) {
      if (hasOwnProperty(error.response.data, "message")) {
        const { data } = error.response;

        if (data.message === "jwt expired") {
          localStorage.removeItem(env.SESSION_KEY);
          window.location.href = "/";
          return;
        }

        let message =
          errorPackage[
            String(data.message).toLowerCase() as keyof typeof errorPackage
          ];

        if (!message) {
          message = data.message;
        }

        // user id에 대한 권한 검사
        if (message.toString() === "-5") {
          message = "권한이 없습니다.";
        }

        response = {
          status: data.status || "error",
          message: message,
          original: data.message,
        };
      }
    }

    throw {
      statusCode,
      message: !response ? statusText.toLoweCase() : response.message,
      original: !response ? null : response.original,
    };
  }
);

export const addHeaderToken = (token: string) => {
  fetch.defaults.headers.common["authorization"] = `Bearer ${token}`;
};

export const removeHeaderToken = () => {
  delete fetch.defaults.headers.common.authorization;
};

export default fetch;
