import { IAlert } from "interfaces/common.interface";
import { useEffect } from "react";
import { classNameJoin } from "utils/ui.lib";

interface Props extends IAlert {
  onHide: (uid: string) => void;
}

const Alert = ({ message, status, uid, onHide }: Props) => {
  useEffect(() => {
    setTimeout(() => {
      onHide(uid);
    }, 3000);
  }, [uid, onHide]);

  const statusColor =
    status === "debug"
      ? "bg-slate-500"
      : status === "error"
      ? "bg-red-500"
      : "bg-emerald-500";

  return (
    <li
      className={classNameJoin([
        "whitespace-nowrap py-3 px-5 text-white rounded-md text-sm",
        statusColor,
      ])}
    >
      {message}
    </li>
  );
};

export default Alert;
