import { MovingAverageMethodType } from "interfaces/backtest.interface";

interface Props {
  checked: MovingAverageMethodType;
  onChange: (name: MovingAverageMethodType) => void;
}

const methods: MovingAverageMethodType[] = ["DEMA", "EMA", "HMA", "LSMA", "SMA", "SMMA", "TEMA", "WMA"];

export default function MovingAverageMethod({ checked, onChange }: Props) {
  return (
    <ul className="space-y-0.5">
      {methods.map((name) => {
        return (
          <li key={name}>
            <label className="flex items-center">
              <input type="radio" name="mam" checked={name === checked} onChange={() => onChange(name)} />
              <span className="ml-2">{name}</span>
            </label>
          </li>
        );
      })}
    </ul>
  );
}
