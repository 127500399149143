import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface IParams {
  defaultPage?: string | number;
  defaultLimit?: string | number;
}

const usePagination = ({ defaultPage = 1, defaultLimit = 20 }: IParams) => {
  const [searchParams, replace] = useSearchParams();
  const [page, setPage] = useState<string | number>(
    parseInt(searchParams.get("page") || defaultPage.toString())
  );
  const [limit, setLimit] = useState<string | number>(
    parseInt(searchParams.get("limit") || defaultLimit.toString())
  );

  const movePage = useCallback(
    (p: number, etc: { [key: string]: string } = {}) => {
      setPage(p);

      searchParams.set("page", p.toString());

      for (let key in etc) {
        if (etc[key]) {
          searchParams.set(key, etc[key]);
        } else {
          if (searchParams.get(key)) {
            searchParams.delete(key);
          }
        }
      }

      const search = searchParams.toString();

      replace("?" + search);
    },
    [searchParams, replace]
  );

  const changeLimit = useCallback(
    (l: number) => {
      setLimit(l);
      searchParams.set("limit", l.toString());
      const search = searchParams.toString();

      replace("?" + search);
    },
    [searchParams, replace]
  );

  return { page, limit, searchParams, movePage, changeLimit };
};

export default usePagination;
