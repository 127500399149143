const error = {
  "not found": "잘못된 경로입니다.",
  "validation failed": "필수값을 모두 입력해주세요.",
  "token not defined": "일치하는 정보가 없습니다.",
  "user not matched": "일치하는 사용자 정보가 없습니다.",
  "jwt expired": "인증정보가 만료되었습니다.",
  "database error": "오류가 발생했습니다.",
  "not found theme": "관련 데이터가 없습니다.",
};

export default error;
