import "styles/ui/switch.css";
import { classNameJoin } from "utils/ui.lib";

interface Props {
  className?: string;
  name?: string;
  defaultChecked?: boolean;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

const Switch = ({ className, name, defaultChecked, onChange }: Props) => {
  return (
    <label className={classNameJoin(["ui-switch", className])}>
      <input
        name={name}
        defaultChecked={defaultChecked}
        type="checkbox"
        onChange={onChange}
      />
      <div className="ui-switch-bar" />
    </label>
  );
};

export default Switch;
