import { useRef } from "react";
import { StyledColor } from "./styles";

interface Props {
  color: string;
  onChange: (value: string) => void;
}

export default function ColorBox({ color, onChange }: Props) {
  const ref = useRef<HTMLInputElement | null>(null);

  return (
    <StyledColor
      style={{ backgroundColor: color }}
      onClick={() => ref.current?.click()}
    >
      <input
        tabIndex={-1}
        type="color"
        ref={ref}
        defaultValue={color}
        onChange={(ev) => onChange(ev.target.value)}
      />
    </StyledColor>
  );
}
