import * as d3 from "d3";
import { Frame } from "interfaces/common.interface";
import { ITargetPrice } from "interfaces/dart.interface";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { comma } from "utils/lib";

type D3_SVGElement = d3.Selection<SVGSVGElement, unknown, null, any>;

type Data = {
  x: Date;
  y: number;
};

interface Props {
  data: ITargetPrice[];
  price: {
    avg: string | number;
    current: string | number;
  };
}

const MARGIN = {
  top: 30,
  bottom: 60,
  left: 60,
  right: 20,
};

export default function TargetPriceGraph({ data, price }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const svg = useRef<D3_SVGElement | null>(null);
  const tooltip = useRef<any>(null);

  const [frame, setFrame] = useState<Frame>({ width: 0, height: 0 });

  const currentPrice = isNaN(Number(price.current)) ? 0 : Number(price.current);
  const averagePrice = isNaN(Number(price.avg)) ? 0 : Number(price.avg);

  // DATA
  const _data = useMemo(() => {
    return data
      .map(({ price, registed_at }) => ({
        y: isNaN(parseInt(price)) ? 0 : parseInt(price),
        x: new Date(registed_at),
      }))
      .filter((d) => d.y !== 0);
  }, [data]);

  const average = useMemo(() => {
    const result: Data[] = [];
    const group: { [key: number]: number[] } = {};

    for (let i = 0; i < _data.length; i++) {
      const { x, y } = _data[i];

      const key = x.getTime();

      if (!group[key]) group[key] = [y];
      group[key].push(y);
    }

    for (let key in group) {
      const count = group[key].length;
      const sum = group[key].reduce((p, c) => p + c, 0);
      result.push({ y: Math.floor(sum / count), x: new Date(parseInt(key)) });
    }

    return result;
  }, [_data]);

  /**
   *
   */
  const ylim = useMemo(() => {
    const y = _data.map((d) => d.y);
    const max = Math.max(...y, currentPrice);
    const min = Math.min(...y, currentPrice);

    const lmin = min * 0.95;
    const lmax = max * 1.03;

    return [min, max, lmin, lmax];
  }, [_data, currentPrice]);

  // EVENT
  const resize = () => {
    if (!ref.current) return;

    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;

    const width = ref.current.getBoundingClientRect().width;
    const height = Math.floor(
      (width / 16) * (innerWidth > innerHeight ? 5.5 : 10)
    );

    setFrame((prevState) => {
      if (prevState.width !== width || prevState.height !== height) {
        return { width, height };
      }

      return prevState;
    });
  };

  const drawLeftAxis = useCallback(
    (svg: D3_SVGElement, frame: Frame) => {
      const [min, max, lmin, lmax] = ylim;

      const height = frame.height - MARGIN.top - MARGIN.bottom;

      const yScale = d3.scaleLinear().domain([lmin, lmax]).range([height, 0]);

      const distance = max - min;
      const gap = Math.floor(distance / 5);
      const values = Array(6)
        .fill(undefined)
        .map((_, i) => {
          return gap * i + min;
        });

      const g = svg
        .append("g")
        .attr("transform", `translate(${MARGIN.left}, ${MARGIN.top})`);
      g.call(
        d3
          .axisLeft(yScale)
          .tickValues([...values, max])
          .tickSizeOuter(0)
      );

      return yScale;
    },
    [ylim]
  );

  const drawBottomAxis = useCallback(
    (svg: D3_SVGElement, frame: Frame, data: typeof _data) => {
      const width = frame.width - MARGIN.left - MARGIN.right;
      const date = data.map((d) => d.x);

      const times = new Set(date.map((d) => d.getTime()));

      const values = Array.from(times).map((t) => new Date(t));

      const xScale = d3
        .scalePoint<Date>()
        .domain(date)
        .padding(0.5)
        .align(0.5)
        .range([width, 0]);

      const g = svg
        .append("g")
        .attr(
          "transform",
          `translate(${MARGIN.left}, ${frame.height - MARGIN.bottom})`
        );

      g.call(
        d3
          .axisBottom<Date>(xScale)
          .tickFormat(d3.utcFormat("%Y %m %d"))
          .tickValues(values)
      )
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)");

      return xScale;
    },
    []
  );

  const mouseover = function () {
    d3.select(this).style("font-weight", 700);
  };

  const mouseleave = function () {
    d3.select(this).style("font-weight", 400);
  };

  const draw = useCallback(
    (frame: Frame) => {
      if (!ref.current) return;
      if (svg.current) svg.current.remove();

      const { width, height } = frame;

      svg.current = d3
        .select(ref.current)
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      svg.current.select("svg").attr("width", width).attr("height", height);

      const xScale = drawBottomAxis(svg.current, frame, _data);
      const yScale = drawLeftAxis(svg.current, frame);

      const g = svg.current
        .append("g")
        .attr("transform", `translate(${MARGIN.left}, ${MARGIN.top})`);

      // average line
      g.append("path")
        .datum(average)
        .attr("fill", "none")
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .attr(
          "d",
          d3
            .line<Data>()
            .x((d) => xScale(d.x) || 0)
            .y((d) => yScale(d.y))
        );

      // average price
      g.append("path")
        .datum(average)
        .attr("fill", "none")
        .attr("stroke", "blue")
        .attr("stroke-width", 1)
        .attr(
          "d",
          d3
            .line<Data>()
            .x((d) => xScale(d.x) || 0)
            .y(yScale(averagePrice))
        );

      g.append("text")
        .attr("x", 10)
        .attr("y", yScale(averagePrice) - 4)
        .attr("fill", "blue")
        .style("font-size", 10)
        .text(comma(averagePrice));

      // current price
      g.append("path")
        .datum(average)
        .attr("fill", "none")
        .attr("stroke", "red")
        .attr("stroke-width", 1)
        .attr(
          "d",
          d3
            .line<Data>()
            .x((d) => xScale(d.x) || 0)
            .y(yScale(currentPrice))
        );

      g.append("text")
        .attr("x", 10)
        .attr("y", yScale(currentPrice) - 4)
        .attr("fill", "red")
        .style("font-size", 10)
        .text(comma(currentPrice));

      // circle
      _data.forEach(function ({ x, y }) {
        g.append("circle")
          .attr("r", 2)
          .attr("cx", xScale(x) || 0)
          .attr("cy", yScale(y))
          .attr("fill", "black");

        g.append("text")
          .attr("x", xScale(x) || 0)
          .attr("y", yScale(y) - 6)
          .attr("text-anchor", "middle")
          .style("font-size", 10)
          .text(comma(y))
          .on("mouseover", mouseover)
          .on("mouseleave", mouseleave);

        if (d3.select(".tooltip")) d3.select(".tooltip").remove();

        tooltip.current = d3
          .select(ref.current)
          .append("div")
          .classed("tooltip", true)
          .style("opacity", 0)
          .style("background-color", "white")
          .style("border", "solid")
          .style("border-width", 1)
          .style("border-radius", 4)
          .style("padding", 4);
      });
    },
    [_data, drawBottomAxis, drawLeftAxis, average, currentPrice, averagePrice]
  );

  useEffect(() => {
    draw(frame);
  }, [draw, frame]);

  useEffect(() => {
    if (!ref.current) return;

    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;

    const width = ref.current.getBoundingClientRect().width;
    const height = Math.floor(
      (width / 16) * (innerWidth > innerHeight ? 5.5 : 12)
    );

    setFrame({ width, height });

    draw(frame);

    window.addEventListener("resize", resize);

    return function cleanup() {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return <div ref={ref} id="template"></div>;
}
