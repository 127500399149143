import React, { useCallback, useMemo, useState } from "react";
import { Modal } from "components/shared";

interface IConfirm {
  title: string;
  content: string;
  onConfirm: () => void;
  onCancle?: () => void;
}

interface IConfirmContext {
  show: (props: IConfirm) => void;
}

export const ConfirmContext = React.createContext<IConfirmContext>(null!);

const ConfirmProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<IConfirm>({
    title: "",
    content: "",
    onConfirm: () => {},
    onCancle: () => {},
  });

  const show = useCallback(
    ({ title, content, onConfirm, onCancle }: IConfirm) => {
      setConfirm({
        title,
        content,
        onConfirm,
        onCancle,
      });

      setToggle(true);
    },
    []
  );

  const confirmHandler = () => {
    confirm.onConfirm();
    setToggle(!toggle);
  };

  const cancleHandler = () => {
    confirm.onCancle && confirm.onCancle();
    setToggle(!toggle);
  };

  const value = useMemo(
    () => ({
      show,
    }),
    [show]
  );

  return (
    <ConfirmContext.Provider value={value}>
      {children}
      <Modal.Layout id="confirm" show={toggle}>
        <div className="absolute inline-block w-[280px] left-[50%] translate-x-[-50%] bg-white rounded-md overflow-hidden top-[8vh] z-40">
          <div className="p-6">
            <div className="text-center font-bold">{confirm.title}</div>

            <div className="mt-4">
              <p
                className="text-sm text-center break-words text-neutral-700"
                dangerouslySetInnerHTML={{ __html: confirm.content }}
              />
            </div>
          </div>
          <div className="flex text-sm">
            <button
              className="flex-1 py-2.5 bg-neutral-50"
              onClick={cancleHandler}
            >
              취소
            </button>
            <button
              className="flex-1 py-2.5 bg-neutral-700 text-white"
              onClick={confirmHandler}
            >
              확인
            </button>
          </div>
        </div>
      </Modal.Layout>
    </ConfirmContext.Provider>
  );
};

export default ConfirmProvider;
