import { classNameJoin } from "utils/ui.lib";

interface ItemProps extends React.HTMLAttributes<HTMLSpanElement> {}

const Item = ({
  children,
  className,
  ...props
}: React.PropsWithChildren<ItemProps>) => {
  // bg-slate-600 hover:bg-slate-500 text-white transition duration-300 rounded font-normal text-[13px] py-1 px-2 whitespace-nowrap cursor-pointer -translate-y-[1px]
  return (
    <div className="inline-block p-0.5">
      <span
        {...props}
        className={classNameJoin(["test-slate-600 text-sm p-1", className])}
      >
        <span>#</span>
        <span className="inline-block">{children}</span>
      </span>
    </div>
  );
};

interface GroupProps {
  length: number;
}

const Group = ({ children, length }: React.PropsWithChildren<GroupProps>) => {
  if (length === 0) {
    return (
      <div>
        <span className="text-sm text-neutral-400"># 태그없음</span>
      </div>
    );
  }

  return <div className="flex flex-wrap items-center">{children}</div>;
};

export default {
  Item,
  Group,
};
