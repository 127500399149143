import { Link } from "react-router-dom";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  link?: boolean;
}

const Anchor = ({
  children,
  link,
  ...props
}: React.PropsWithChildren<Props>) => {
  if (link) {
    return (
      <Link className="text-blue-500" to={props.href || "/"}>
        {children}
      </Link>
    );
  }

  return (
    <a
      className="text-blue-500"
      href={props.href}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export default Anchor;
