import React, { useEffect, useRef } from "react";
import { classNameJoin } from "utils/ui.lib";

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  autoResize?: boolean;
  minHeight?: number | string;
  maxHeight?: number | string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      className,
      style,
      autoResize = true,
      minHeight = 100,
      maxHeight = 300,
      onChange,
      ...props
    },
    forwardRef
  ) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const changeHandler = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange && onChange(ev);
    };

    useEffect(() => {
      if (textareaRef.current && minHeight) {
        textareaRef.current.style.setProperty("height", minHeight + "px");
      }
    }, [minHeight]);

    useEffect(() => {
      if (textareaRef.current) {
        const { scrollHeight } = textareaRef.current;
        textareaRef.current.style.setProperty("height", scrollHeight + "px");
      }
    }, []);

    useEffect(() => {
      if (props.value && autoResize && textareaRef.current) {
        textareaRef.current.style.setProperty("height", "inherit");
        const { scrollHeight } = textareaRef.current;
        textareaRef.current.style.setProperty("height", scrollHeight + "px");
      }
    }, [props.value, props.defaultValue, autoResize]);

    return (
      <textarea
        {...props}
        ref={(ref) => {
          textareaRef.current = ref;

          if (typeof forwardRef === "function") {
            forwardRef(ref);
          } else if (forwardRef) {
            forwardRef.current = ref;
          }
        }}
        style={{ minHeight, maxHeight, ...style }}
        className={classNameJoin([
          "resize-none w-full bg-transparent p-3 rounded-none outline-none",
          className,
        ])}
        onChange={changeHandler}
      />
    );
  }
);

export default Textarea;
