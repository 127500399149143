import React, { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { getTargetPrice } from "api/company";
import { Progress } from "components/shared";
import { comma } from "utils/lib";
import { nl2br } from "utils/ui.lib";
import { useRecoilValue } from "recoil";
import { userIdState } from "store/user";
import TargetPriceGraph from "components/graph/TargetPriceGraph";

interface Props {
  code: string;
  tclp: string;
}

const TargetPrice = ({ code, tclp }: Props) => {
  const [expanded, setExpanded] = useState<number[]>([]);

  const userId = useRecoilValue(userIdState);

  const { data, isLoading, isError } = useQuery(
    ["target-price", code, userId],
    async () => {
      if (!code || userId === -1) return null;

      const { data } = await getTargetPrice({
        search: code,
        category: "code",
        limit: 10,
      });

      if (!data.data) return null;

      const items = data.data.data;

      return {
        items: items.map(({ comment, registed_at, ...rest }) => {
          return {
            comment: nl2br(comment),
            registed_at: dayjs(registed_at).format("YYYY-MM-DD"),
            ...rest,
          };
        }),
        avg: data.data.avg,
        total: data.meta.total,
      };
    }
  );

  /**
   * 등락률 계산
   */
  const calcRate = useCallback(
    (tragetprice: string | number) => {
      if (!tragetprice) return "";

      const current = parseInt(tclp);
      const diff = parseInt(tragetprice.toString()) - current;
      const share = current / 100;
      return parseFloat((diff / share).toFixed(2));
    },
    [tclp]
  );

  const upperRate = useMemo(() => {
    if (!data || data.avg === 0) return 0;

    return calcRate(data.avg);
  }, [data, calcRate]);

  return (
    <div className="py-4">
      <h5 className="flex font-bold text-lg mr-2 justify-between items-center">
        <div>
          <span>목표주가</span>
          {isLoading && <Progress size={12} />}
        </div>
      </h5>

      {isError || !data || data.items.length === 0 ? (
        <div className="text-center py-8">목표주가가 없습니다.</div>
      ) : (
        <div className="py-2">
          <div>
            <div className="text-lg">평균가격 {comma(data.avg)} 원</div>
            <div className="text-sm">현재가격 {comma(tclp)} 원</div>
            <div className="text-sm text-emerald-500">
              상승여력 {upperRate}%
            </div>
          </div>
          <TargetPriceGraph
            data={data.items}
            price={{ avg: data.avg, current: tclp }}
          />
        </div>
      )}
    </div>
  );
};

export default TargetPrice;
