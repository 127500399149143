import { useCallback, useEffect, useMemo, useState } from "react";
import { classNameJoin } from "utils/ui.lib";
import Icon from "./Icon";
import Input from "./Input";

export interface ISelectorOption<T = string> {
  label: string;
  value: T | null;
}

interface IProps {
  className?: string;
  value?: string | null;
  placeholder?: string;
  items: ISelectorOption[];
  onChange: (value: string | null) => void;
}

const Selector = ({
  value,
  className,
  placeholder,
  items,
  onChange,
}: IProps) => {
  const [active, setActive] = useState(false);

  const hide = useCallback(() => {
    setActive(false);
    document.removeEventListener("mousedown", hide);
  }, []);

  const mousedownHandler = useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      if (active) {
        hide();
        return;
      }

      setActive(true);
      document.addEventListener("mousedown", hide);
    },
    [hide, active]
  );

  useEffect(() => {
    return function cleanup() {
      document.removeEventListener("mousedown", hide);
    };
  }, [hide]);

  const label = useMemo(() => {
    const label = items.find((p) => p.value === value);
    return label ? label.label : "";
  }, [items, value]);

  return (
    <div
      className={classNameJoin(["relative inline-flex select-none", className])}
    >
      <div
        className="relative w-full cursor-pointer"
        onMouseUp={mousedownHandler}
      >
        <Input
          inputClassName="pointer-events-none"
          value={label}
          placeholder={placeholder}
          readOnly
        />

        <div className="absolute right-2 top-[53%] -translate-y-[50%] origin-center">
          <Icon
            className={active ? "" : "rotate-180"}
            icon="caret-up"
            fontSize={14}
          />
        </div>
      </div>
      <div
        className={classNameJoin([
          "absolute top-[100%] transition-all left-0 right-0 z-40 bg-white overflow-hidden",
          active
            ? "translate-y-1 opacity-100 visible"
            : "-translate-y-2 opacity-0 invisible",
        ])}
      >
        <ul
          style={{ maxHeight: 300 }}
          className="border border-neutral-200 py-1 rounded overflow-y-auto overflow-x-hidden"
        >
          {items.map((p) => {
            return (
              <li
                key={p.value}
                className={classNameJoin([
                  "cursor-pointer px-4 py-2 text-sm whitespace-nowrap w-full hover:bg-neutral-100",
                  p.value === value
                    ? "bg-neutral-100 text-neutral-800"
                    : "text-neutral-600",
                ])}
                onMouseDown={() => onChange(p.value)}
              >
                {p.label}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Selector;
