import { classNameJoin } from "utils/ui.lib";

interface IProps extends React.HTMLAttributes<HTMLButtonElement> {}

export default function LinkedButton({
  className,
  children,
  ...props
}: React.PropsWithChildren<IProps>) {
  return (
    <button className={classNameJoin(["bg-transparent", className])} {...props}>
      {children}
    </button>
  );
}
