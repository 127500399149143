import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";

import "styles/ui/dropdown.css";
import { classNameJoin } from "utils/ui.lib";

interface IProps extends IDropdownContext {
  label: string | React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
}

interface IDropdownContext {
  onTrigger?: (value: any) => void;
}

const portalElement = document.querySelector("#portal");
const DropdownContext = React.createContext<IDropdownContext>(null!);

function Dropdown({
  children,
  label,
  onTrigger,
  ...props
}: React.PropsWithChildren<IProps>) {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const [wrapperRect, setWrapperRect] = useState<DOMRect | null>(null);

  const toggleHandler = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();

    if (toggle) {
      setToggle(false);
      return;
    }

    setToggle(true);
  };

  const hide = () => {
    setToggle(false);
  };

  const triggerHandler = (value: any) => {
    onTrigger && onTrigger(value);
    hide();
  };

  const reportPositionHandler = useCallback((rect: DOMRect) => {
    setWrapperRect(rect);
  }, []);

  const style = useMemo<React.CSSProperties | undefined>(() => {
    if (dropdownRef.current && wrapperRect) {
      const style: React.CSSProperties = {};
      const { innerHeight, innerWidth } = window;

      const dropdownRect = dropdownRef.current.getBoundingClientRect();

      if (innerWidth <= dropdownRect.left + wrapperRect.width + 24) {
        style.left = dropdownRect.right - wrapperRect.width;
      } else {
        style.left = dropdownRect.left;
      }

      if (innerHeight < dropdownRect.bottom + wrapperRect.height) {
        style.top =
          dropdownRect.top + window.scrollY - (wrapperRect.height + 6);
      } else {
        style.top = dropdownRect.bottom + window.scrollY + 6;
      }

      return {
        ...style,
        minWidth: dropdownRect.width,
      };
    }

    return undefined;
  }, [wrapperRect]);

  useEffect(() => {
    document.addEventListener("mousedown", hide);

    return function cleanup() {
      document.removeEventListener("mousedown", hide);
    };
  }, []);

  const value: IDropdownContext = {
    onTrigger: triggerHandler,
  };

  return (
    <DropdownContext.Provider value={value}>
      <div className="ui-dropdown inline-flex" ref={dropdownRef}>
        <button onMouseDown={toggleHandler}>{label}</button>
        {ReactDOM.createPortal(
          <Wrapper
            style={style}
            toggle={toggle}
            onReportPosition={reportPositionHandler}
          >
            {children}
          </Wrapper>,
          portalElement!
        )}
      </div>
    </DropdownContext.Provider>
  );
}

function Wrapper({
  children,
  style,
  toggle,
  onReportPosition,
}: React.PropsWithChildren<{
  style?: React.CSSProperties;
  toggle: boolean;
  onReportPosition: (position: DOMRect) => void;
}>) {
  const wrapperRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    if (wrapperRef.current) {
      if (toggle) {
        onReportPosition(wrapperRef.current.getBoundingClientRect());
        wrapperRef.current.classList.add("visible");
      } else {
        wrapperRef.current.classList.remove("visible");
      }
    }
  }, [toggle, onReportPosition]);

  return (
    <ul
      ref={wrapperRef}
      style={style}
      className="ui-dropdown-wrapper absolute whitespace-nowrap select-none bg-white border py-1 text-sm rounded-md z-30"
    >
      {children}
    </ul>
  );
}

Dropdown.Item = function Item({
  children,
  value,
  disabled,
}: React.PropsWithChildren<{ value: any; disabled?: boolean }>) {
  const { onTrigger } = useContext(DropdownContext);

  return (
    <li
      className={classNameJoin([
        disabled
          ? "bg-neutral-100 text-neutral-500"
          : "cursor-pointer hover:bg-neutral-100",
        "px-3 py-2",
      ])}
      onClick={() => {
        if (disabled) return;
        onTrigger && onTrigger(value);
      }}
    >
      {children}
    </li>
  );
};

export default Dropdown;
