const NODE_ENV = process.env.REACT_APP_NODE_ENV as string;

const env = {
  NODE_ENV,
  BASE_URL: process.env.REACT_APP_BASE_URL as string,
  APP_VERSION:
    NODE_ENV === "development" ? "DEVELOPMENT" : process.env.REACT_APP_VERSION,
  SESSION_KEY: process.env.REACT_APP_SESSION_KEY as string,
  SAVE_KEY: process.env.REACT_APP_SAVE_KEY as string,
};

export default env;
