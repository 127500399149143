import * as d3 from "d3";

/**
 * @param {Date[]} domain
 * @param {number[]} range
 * @returns
 *
 * ```javascript
 * const r = invert(domain, range)(x);
 * ```
 */
export default function invert(domain: Date[], range: number[]) {
  let r0 = range[0],
    r1 = range[1];

  const count = domain.length + 1;
  const share = Math.abs(r0 - r1) / count;
  const dividen = new Array(count).fill(undefined).map((_, i) => share * i);

  return function (x: number) {
    const i = d3.bisector((d) => d).right(dividen, x);
    return domain[i - 1];
  };
}
